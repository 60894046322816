import { Card, Checkbox, type CheckboxOptionType } from "antd";
import { type CheckboxGroupProps } from "antd/es/checkbox/Group";
import { type ReactNode } from "react";

import cn from "@/utils/cn";

const { Group } = Checkbox;

export type MyCheckboxGroupProps = {
	options: CheckboxOptionType[];
	header?: ReactNode;
	cardClassName?: string;
	containerClassName?: string;
	loading?: boolean;
} & CheckboxGroupProps;

export default function CheckboxGroup({
	options,
	containerClassName,
	cardClassName,
	className,
	header,
	loading = false,
	...props
}: MyCheckboxGroupProps) {
	return (
		<div className={cn("@container flex flex-col", containerClassName)}>
			{header}

			<Card
				className={cn(
					"max-h-[300px] flex-1 overflow-y-auto p-5",
					cardClassName,
				)}
				loading={loading}
				bodyStyle={{ padding: 0 }}
			>
				<Group
					options={options}
					className={cn("flex flex-col gap-1", className)}
					{...props}
				/>
			</Card>
		</div>
	);
}
