import { fetchClient } from "@/definitions";
import establishment, {
	Establishment,
	EstablishmentType,
	ExternalEstablishment,
	externalEstablishment,
} from "@/utils/schemas/establishment";

export const createEstablishment = async (
	data: Partial<Establishment> | Partial<ExternalEstablishment>,
	type: EstablishmentType,
) => {
	const res = await fetchClient(
		type === "external" ? "Establishment/External" : "Establishment",
		{
			method: "POST",
			body: JSON.stringify(data),
		},
	);

	return (type === "external" ? externalEstablishment : establishment).parse(
		res,
	);
};

export const updateEstablishment = async (
	data: (Partial<Establishment> | Partial<ExternalEstablishment>) &
		Pick<Establishment, "id">,
	type: EstablishmentType,
) => {
	const res = await fetchClient(
		type === "external"
			? `Establishment/External/${data.id}`
			: `Establishment/${data.id}`,
		{
			method: "PATCH",
			body: JSON.stringify(data),
		},
	);

	return (type === "external" ? externalEstablishment : establishment).parse(
		res,
	);
};

export const deleteEstablishment = async (id: number, type: EstablishmentType) => {
	await fetchClient(
		type === "external" ? `Establishment/External/${id}` : `Establishment/${id}`,
		{
			method: "DELETE",
		},
	);

	return;
};
