import { storageKeys } from "@/definitions/global-keys";
import { env } from "@/env";

export function fetchClient<T = unknown>(
	url: string,
	params?: RequestInit & { omitHeaders?: string[] },
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
): Promise<T | void> {
	const { headers: headerParams, omitHeaders = [], ...rest } = params ?? {};
	const baseUrl = env.VITE_PUBLIC_BACKEND_URL;
	const authToken = localStorage.getItem(storageKeys.authToken)!;

	const headers = {
		accept: "*/*",
		"Content-Type": "application/json",
		...(headerParams ?? {}),
	} as Record<string, string>;

	if (authToken) {
		headers.Authorization = `Bearer ${authToken}`;
	}

	omitHeaders.forEach((key) => {
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
		delete headers[key];
	});

	return fetch(`${baseUrl}/${url}`, {
		...rest,
		headers,
	}).then(
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
		(res): Promise<T | void> =>
			res
				.json()
				// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
				.then((data): T | void => {
					if (res.status >= 200 && res.status <= 300) {
						return data as T;
					}

					console.log("error data", data);
					throw new Error(
						// @ts-expect-error
						(data.Message ?? data.title ?? "Something went wrong") as string,
					);
				})
				.catch((e) => {
					if (res.status >= 200 && res.status <= 300) return;
					// case when BE response is empty but status is not 200
					throw e;
				}),
	);
}
