import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

export const env = createEnv({
	/**
	 * Specify what prefix the client-side variables must have.
	 * This is enforced both on type-level and at runtime.
	 */
	clientPrefix: "VITE_PUBLIC_",
	server: {},
	client: {
		VITE_PUBLIC_BACKEND_URL: z.string().url(),
		VITE_PUBLIC_MEDIA_URL: z.string().url(),
	},
	/**
	 * What object holds the environment variables at runtime.
	 * Often `process.env` or `import.meta.env`
	 */
	runtimeEnv: import.meta.env,
});
