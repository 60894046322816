import { z } from "zod";

import user from "./user";

const attachment = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().nullish(),
	size: z.number(),
	path: z.string(),
	fileType: z.number(),
	created: z.string().nullish(),
	updated: z.string().nullish(),
});

const alert = z.object({
	id: z.number(),
	triggerDate: z.string(),
	title: z.string(),
	description: z.string().nullable(),
	notifyViaEmail: z.boolean(),
	hourOfReminder: z.string(),
	alertRepeatings: z
		.array(
			z.object({
				id: z.number(),
				alertRepeating: z.number(),
				unitNumber: z.number(),
			}),
		)
		.nullish(),
	created: z.string().optional(),
	updated: z.string().optional(),
	users: z.array(user).nullish(),
	attachment: attachment.nullish(),
});
export default alert;

export type Alert = z.infer<typeof alert>;
export type AlertAttachment = z.infer<typeof attachment>;
