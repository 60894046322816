import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { type Key } from "react";

import { type Project } from "@/utils/schemas";

type AlertColumnProps = {
	alerts: Project["alerts"];
	project: Project;
	selectedRowKeys: Key[];
};

export default function AlertColumn({
	alerts,
	project,
	selectedRowKeys,
}: AlertColumnProps) {
	const areAlertsEmpty = !alerts?.length;
	if (areAlertsEmpty) return <span className="text-gray-400">-</span>;

	const isSelected = selectedRowKeys.some((key) => project.id === key);
	if (isSelected)
		return (
			<ul className="list-none p-0">
				{alerts.map((alert) => (
					<li key={alert.id}>
						<p style={{ padding: 0, margin: 0 }}>
							<b>{alert.title}: </b>
							<Popover
								placement="bottom"
								title="Notifier"
								content={
									<ul style={{ listStyle: "none", padding: 0 }}>
										{alert.users?.map((user) => (
											<li key={user.id}>{`${user.name} ${user.lastName}`}</li>
										))}
									</ul>
								}
							>
								<InfoCircleOutlined />
							</Popover>
							{new Date(alert.triggerDate).toLocaleDateString("fr-CH")}
						</p>
					</li>
				))}
			</ul>
		);
	else if (project.nextAlert)
		return (
			<>
				<b>
					<span className={"mr-1"}>{project.nextAlert.title}</span>
					<Popover
						placement="bottom"
						title="Notifier"
						content={
							<ul style={{ listStyle: "none", padding: 0 }}>
								{project.nextAlert.users?.map((user) => (
									<li key={user.id}>{`${user.name} ${user.lastName}`}</li>
								))}
							</ul>
						}
					>
						<InfoCircleOutlined />
					</Popover>
				</b>

				<p>
					{new Date(project.nextAlert.triggerDate).toLocaleDateString("fr-CH")}
				</p>
			</>
		);
	else return <span className="text-gray-400">-</span>;
}
