import { UseRequestParamsReturn } from "@/hooks";
import { UserQueryOptions } from "@/hooks/query/useUserData";
import { paramsToValuesArray } from "@/utils/params-to-values-array";

type Params = (string | number | undefined | null)[];

const mutationKeys = {
	establishments: (params: Params = []) => ["establishments", ...params],
	users: (options: UserQueryOptions, params: Params = []) => [
		"users",
		options,
		...params,
	],
	project: (id?: number | string) => ["project", id],
	projects: (
		params: Pick<
			UseRequestParamsReturn,
			"orderBy" | "pagination" | "reqParams"
		>,
	) => ["projects", ...paramsToValuesArray(params)],
};

export default mutationKeys;
