import {
	type UseMutationOptions,
	type UseQueryOptions,
	useQuery,
} from "@tanstack/react-query";
import { z } from "zod";

import { TypeFormInputs } from "@/components/project-list/settings/AddTypeForm";
import { fetchClient } from "@/definitions";
import { ProjectType, projectType } from "@/utils/schemas/project";

type ProjectTypeOptions = {
	projectId?: number | string;
};

export function projectTypeQuery(options?: ProjectTypeOptions) {
	const { projectId } = options ?? {};
	return {
		queryKey: ["projectTypes", projectId],
		queryFn: async () => {
			const res = await fetchClient(
				`ProjectType${projectId ? `?projectId=${projectId}` : ""}`,
			);

			return z.array(projectType).parse(res);
		},
	} satisfies UseQueryOptions;
}

export function createProjectTypeQuery() {
	return {
		mutationKey: ["projectType", "create"],
		mutationFn: async (data) => {
			const res = await fetchClient<ProjectType>("ProjectType", {
				method: "POST",
				body: JSON.stringify(data),
			});

			return projectType.parse(res);
		},
	} satisfies UseMutationOptions<ProjectType, unknown, TypeFormInputs>;
}

export function toggleProjectTypeQuery() {
	return {
		mutationKey: ["projectTypes", "toggle"],
		mutationFn: async (id: number) =>
			fetchClient(`ProjectType/ChangeStatus/${id}`, { method: "PATCH" }),
	} satisfies UseMutationOptions<void, unknown, number>;
}

export function deleteProjectTypeQuery() {
	return {
		mutationKey: ["projectType", "delete"],
		mutationFn: async (id: number) => {
			await fetchClient(`ProjectType/${id}`, {
				method: "DELETE",
			});
		},
	} satisfies UseMutationOptions<void, unknown, number>;
}

export default function useProjectType(options?: ProjectTypeOptions) {
	return useQuery(projectTypeQuery(options));
}
