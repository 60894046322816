import { fetchClient } from "@/definitions";
import { FileForEntity } from "@/utils/schemas";

export const uploadFile = async (
	file: File,
	data: {
		entity: FileForEntity;
		entityId: string;
		fileType: number;
	},
) => {
	const { entity, entityId, fileType } = data;

	const formData = new FormData();
	formData.append("file", file);
	formData.append("fileType", fileType.toString());

	const res = await fetchClient(`Files/${entity}/${entityId}`, {
		method: "POST",
		omitHeaders: ["Content-Type"],
		body: formData,
	});

	return res;
};

export const deleteFile = async (fileId: number) => {
	await fetchClient(`Files/${fileId}`, {
		method: "DELETE",
	});

	return null;
};

export const updateFileType = async (fileId: number, fileType: number) => {
	await fetchClient(`Files/${fileId}`, {
		method: "PATCH",
		body: JSON.stringify({ fileType }),
	});

	return null;
};
