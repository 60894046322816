import {
	ArrowLeftOutlined,
	BellOutlined,
	CalendarOutlined,
	DeleteFilled,
	HourglassOutlined,
	PlusCircleOutlined,
	TagOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { type QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import {
	Button,
	Card,
	ConfigProvider,
	DatePicker,
	Divider,
	Form,
	Input,
	Layout,
	Modal,
	Popconfirm,
	Select,
	Spin,
	Typography,
	message,
} from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
	type LoaderFunctionArgs,
	useNavigate,
	useParams,
} from "react-router-dom";
import { z } from "zod";

import {updateProjectExternalUsers, updateProjectFileReferent, updateProjectUserRole} from "@/api";
import { getProject } from "@/api";
import { ReactComponent as MenuOutlined } from "@/assets/icons/menu.svg";
import { ReactComponent as MoneyOutlined } from "@/assets/icons/money.svg";
import { ReactComponent as PenAndPaperOutlined } from "@/assets/icons/pen-and-paper.svg";
import Label from "@/components/Label";
import StateToggle from "@/components/StateToggle";
import AlertComponent from "@/components/project-list/modify-project/AlertComponent";
import AlertModal from "@/components/project-list/modify-project/AlertModal";
import { CheckboxGroupModule } from "@/components/project-list/modify-project/CheckBoxModule";
import { DocumentsForm } from "@/components/project-list/modify-project/documents-form";
import {
	USER_ESTABLISHMENT_ROLES,
	USER_PROJECT_ROLES,
	userProjectRolesArray,
} from "@/constants/enums";
import {
	DrawerContext,
	ModuleSettingsContext,
	UserContext,
	useToast,
} from "@/context";
import mutationKeys from "@/definitions/mutation-keys";
import useEstablishmentData, {
	establishmentQuery,
} from "@/hooks/query/useEstablishmentData";
import useFinanceType from "@/hooks/query/useFinanceType";
import {
	PatchProjectData,
	deleteProjectQuery,
	patchProjectQuery,
	projectQuery,
} from "@/hooks/query/useProjectData";
import useProjectStage, {
	projectStageQuery,
} from "@/hooks/query/useProjectStage";
import useProjectType from "@/hooks/query/useProjectType";
import useUserData from "@/hooks/query/useUserData";
import { useDebounce } from "@/hooks/useDebounce";
import { useI18nContext } from "@/i18n/i18n-react";
import { type Alert } from "@/utils/schemas/alert";
import projectSchema, { Project } from "@/utils/schemas/project";
import whatHasChangedInArray from "@/utils/whatHasChangedInArray";

const formInputs = z.object({
	name: projectSchema.shape.name,
	description: projectSchema.shape.description,
	active: projectSchema.shape.active,
	stage: z.number(),
	usersRoles: z
		.object({
			leader: z.number().optional(),
			referent: z.number().optional(),
			tutor: z.number().optional(),
		})
		.optional(),
	externalIntervenant: z.number().optional(),
	fileReferent: z.number().optional(),
	projectTypes: z.array(z.number()).optional(),
	finances: z.array(z.number()).optional(),
	establishments: z.array(z.number()).optional(),
	dueDate: projectSchema.shape.dueDate.optional(),
});
export type ProjectForm = z.infer<typeof formInputs>;

const convertUserRoles = (selectedRoles?: Project["usersRoles"]) => {
	const roles: ProjectForm["usersRoles"] = {};

	selectedRoles?.forEach(({ role, user }) => {
		roles[userProjectRolesArray[role] as keyof typeof roles] = user.id;
	});

	return roles;
};

function transformProjectIntoFrom(
	project?: Partial<Project> | null,
): ProjectForm {
	return {
		name: project?.name ?? "",
		description: project?.description ?? "",
		stage: project?.stage?.value ?? 0,
		active: project?.active ?? false,
		dueDate: project?.dueDate,
		establishments: project?.establishments?.map(({ id }) => id),
		projectTypes: project?.projectTypes?.map(({ id }) => id),
		finances: project?.finances?.map(({ id }) => id),
		usersRoles: convertUserRoles(project?.usersRoles),
		externalIntervenant: project?.externalIntervenant?.id,
		fileReferent: project?.fileReferent?.id,
	};
}

const paramsSchema = z.object({
	// Regex which checks if the string contains only numbers
	id: z.string().regex(/^[0-9]+$/),
});

export function loader(queryClient: QueryClient) {
	return async function ({ params }: LoaderFunctionArgs) {
		const result = paramsSchema.safeParse(params);

		if (!result.success) throw new Response("Not Found", { status: 404 });

		const project =
			queryClient.getQueryData(projectQuery(result.data.id).queryKey) ??
			(await queryClient.fetchQuery(projectQuery(result.data.id)));

		if (!project) throw new Response("Not Found", { status: 404 });

		const establishments =
			queryClient.getQueryData(establishmentQuery().queryKey) ??
			queryClient.fetchQuery(establishmentQuery());

		const stages =
			queryClient.getQueryData(projectStageQuery().queryKey) ??
			queryClient.fetchQuery(projectStageQuery());

		return Promise.all([project, establishments, stages]);
	};
}

export default function ProjectForm() {
	const {
		LL: {
			ProjectModule: { projectForm: LL },
			...t
		},
	} = useI18nContext();

	const { toast } = useToast();

	const [messageApi, contextHolder] = message.useMessage();
	const key = "updatable";

	const showPatching = () => {
		messageApi.open({
			key,
			type: "loading",
			content: "Saving...",
			duration: 0,
		});
	};

	const showPatched = () => {
		messageApi.open({
			key,
			type: "success",
			content: "Saved",
		});
	};

	const navigate = useNavigate();
	const { toggleDrawer, setCurrentTab } = useContext(DrawerContext);
	const { isAdmin } = useContext(UserContext);

	const params = paramsSchema.parse(useParams());

	const {
		data: project,
		refetch: refetchProject,
		isLoading,
	} = useQuery({
		queryKey: mutationKeys.project(params.id),
		queryFn: () => getProject(params.id),
		enabled: !!params.id,
		staleTime: 5000,
	});

	const { data: establishments } = useEstablishmentData({
		projectId: params.id,
	});

	const establishmentCheckboxes = useMemo(
		() =>
			establishments?.map(({ id, name }) => ({
				label: name,
				value: id,
			})) ?? [],
		[establishments],
	);
	type EstablishmentCheckboxValue =
		(typeof projectTypesCheckboxes)[number]["value"];

	const { data: projectTypes } = useProjectType({ projectId: params.id });
	const projectTypesCheckboxes = useMemo(
		() =>
			projectTypes?.map(({ id, name }) => ({
				label: name,
				value: id,
			})) ?? [],
		[projectTypes],
	);
	type ProjectTypeCheckboxValue =
		(typeof projectTypesCheckboxes)[number]["value"];

	const { data: financeTypes } = useFinanceType({ projectId: params.id });
	const financeTypesCheckboxes = useMemo(
		() =>
			financeTypes?.map(({ id, name }) => ({
				label: name,
				value: id,
			})) ?? [],
		[financeTypes],
	);
	type FinanceCheckboxValue = (typeof financeTypesCheckboxes)[number]["value"];

	const { data: stages } = useProjectStage({ projectId: params.id });

	const stageOptions = () => {
		const options =
			stages?.map(({ id, name }) => ({
				label: name,
				value: id,
				disabled: false,
				key: id,
				hidden: false,
			})) ?? [];

		if (project?.stage) {
			options.unshift({
				label: project.stage.label,
				hidden: true,
				value: project.stage.value,
				disabled: true,
				key: Date.now(),
			});
		}

		return options;
	};

	const { data: users } = useUserData(null, {
		type: "internal",
		projectId: params.id,
	});
	const usersOptions = (currentRole: number) => {
		const { user } =
			project?.usersRoles?.find((userRole) => currentRole === userRole.role) ??
			{};
		const options = [
			...(users?.map(({ id, name, lastName }) => ({
				label: `${name} ${lastName}`,
				value: id,
				disabled: false,
				key: id,
				hidden: false
			})) ?? []),
		];

		if (user) {
			options.unshift({
				label: `${user.name} ${user.lastName}`,
				value: user.id,
				disabled: true,
				hidden: true,
				key: Date.now(),
			});
		}
		return options;
	};

	const { data: externalUsers } = useUserData(null, {
		type: "external",
		projectId: params.id,
	});

	const externalUsersOptions = () => {
		const user = project?.externalIntervenant;
		const options = [
			...(externalUsers?.map(({ id, name, lastName }) => ({
				label: `${name} ${lastName}`,
				value: id,
				disabled: false,
				key: id,
				hidden: false
			})) ?? []),
		];

		if (user) {
			options.unshift({
				label: `${user.name} ${user.lastName}`,
				value: user.id,
				disabled: true,
				key: Date.now(),
				hidden: true,
			});
		}
		return options;
	};

	const fileReferentUsersOptions = () => {
		const user = project?.fileReferent;
		const options = [
			...(externalUsers?.map(({ id, name, lastName }) => ({
				label: `${name} ${lastName}`,
				value: id,
				disabled: false,
				key: id,
				hidden: false
			})) ?? []),
		];

		if (user) {
			options.unshift({
				label: `${user.name} ${user.lastName}`,
				value: user.id,
				disabled: true,
				key: Date.now(),
				hidden: true,
			});
		}
		return options;
	};


	const initialValues: ProjectForm = useMemo(
		() => transformProjectIntoFrom(project),
		[project],
	);

	const {
		control,
		setValue,
		formState: { isDirty },
		reset,
	} = useForm<ProjectForm>({
		resolver: zodResolver(formInputs),
		defaultValues: initialValues,
	});

	useEffect(() => {
		reset(initialValues);
	}, [initialValues]);

	const { mutate: mutateProject, isLoading: isProjectMutating } = useMutation({
		...patchProjectQuery(params.id),
		onSuccess: async () => {
			await refetchProject();
		},
		onMutate: () => {
			showPatching();
		},
		onSettled: () => {
			showPatched();
		},
	});

	const isFormLoading = useMemo(
		() => isProjectMutating || isLoading,
		[isProjectMutating, isLoading],
	);

	const { mutateAsync: mutateProjectUsersRole } = useMutation<
		Project | null,
		unknown,
		{
			userId: number;
			role: number;
		}
	>({
		mutationFn: (vars) =>
			updateProjectUserRole({ projectId: params.id, ...vars }),
		onSuccess: async () => {
			await refetchProject();
		},
		onMutate: () => {
			showPatching();
		},
		onSettled: () => {
			showPatched();
		},
	});

	const { mutate: mutateProjectExternalUsers } = useMutation<
		Project | null,
		unknown,
		{
			userId: number;
		}
	>({
		mutationFn: (vars) =>
			updateProjectExternalUsers({ projectId: params.id, ...vars }),
		onSuccess: async () => {
			await refetchProject();
		},
		onMutate: () => {
			showPatching();
		},
		onSettled: () => {
			showPatched();
		},
	});

	const { mutate: mutateProjectFileReferent } = useMutation<
		Project | null,
		unknown,
		{
			userId: number;
		}
	>({
		mutationFn: (vars) =>
			updateProjectFileReferent({ projectId: params.id, ...vars }),
		onSuccess: async () => {
			await refetchProject();
		},
		onMutate: () => {
			showPatching();
		},
		onSettled: () => {
			showPatched();
		},
	});

	const formData = useWatch({ control, defaultValue: initialValues });

	const debouncedFormData = useDebounce(formData, 1000);

	useEffect(() => {
		if (isDirty) {
			const parsedFormData: PatchProjectData = {
				...formData,
				establishments: undefined,
				finances: undefined,
				projectTypes: undefined,
			};

			if (formData.establishments) {
				const { added, deleted } = whatHasChangedInArray(
					formData.establishments,
					initialValues.establishments,
				);

				parsedFormData.establishments = {
					added,
					deleted,
				};
			}

			if (formData.stage) {
				parsedFormData.stage = formData.stage;
			}

			if (formData.finances) {
				const { added, deleted } = whatHasChangedInArray(
					formData.finances,
					initialValues.finances,
				);

				parsedFormData.finances = {
					added,
					deleted,
				};
			}

			if (formData.projectTypes) {
				const { added, deleted } = whatHasChangedInArray(
					formData.projectTypes,
					initialValues.projectTypes,
				);

				parsedFormData.projectTypes = {
					added,
					deleted,
				};
			}

			mutateProject(parsedFormData);
		}
	}, [debouncedFormData]);

	const { mutate: deleteProject, isLoading: isDeleteInProgress } = useMutation({
		...deleteProjectQuery(params.id),
		onSuccess: () => {
			toast.success({
				message: t.toasts.success(),
				description: t.toasts.projectDeletedSuccessfully(),
			});
			navigate("/projects");
		},
	});

	function handleDeleteProject() {
		deleteProject();
	}

	function handleRedirect() {
		navigate("/projects");
	}

	const newAlert: Alert = {
		id: -1,
		triggerDate: "",
		title: "",
		description: "",
		notifyViaEmail: true,
		hourOfReminder: "",
		alertRepeatings: [],
		users: [],
	};

	const [currentAlert, setCurrentAlert] = useState<Alert | null>(null);
	function handleChangeCurrentAlert(alert: Alert | null) {
		setCurrentAlert(alert);
	}

	const { dispatchTab } = useContext(ModuleSettingsContext);

	function handleOpenProjectTypesSettings() {
		dispatchTab("project");
	}

	function handleOpenFinancessSettings() {
		dispatchTab("financement");
	}

	const canEdit = () => {
		return (
			isAdmin || project?.accessLevel === USER_ESTABLISHMENT_ROLES.READER_WRITER
		);
	};

	if (!project)
		return (
			<div className="flex-1 flex justify-center items-center">
				<Spin />
			</div>
		);

	return (
		<ConfigProvider
			theme={{
				components: { Button: { paddingContentHorizontal: 0, paddingXS: 0 } },
			}}
		>
			{contextHolder}
			<Layout className="w-full">
				<Button
					type="link"
					className="flex max-w-min items-center text-[#BFBFBF]"
					onClick={handleRedirect}
				>
					<ArrowLeftOutlined />
					Retour
				</Button>

				<form className="flex flex-1">
					<Card
						className="flex flex-1"
						bodyStyle={{
							flex: "1 1 0%",
							display: "flex",
							flexDirection: "column",
						}}
						bordered={false}
					>
						<div className="flex justify-between">
							<Typography>
								<Typography.Title className="text-[#343434] m-0" level={2}>
									{LL.editProject()}
								</Typography.Title>
							</Typography>

							{canEdit() && (
								<Popconfirm
									title={t.popovers.deleteProjectConfirm()}
									onConfirm={handleDeleteProject}
									okText={t.buttons.yes()}
									cancelText={t.buttons.no()}
								>
									<Button
										type="link"
										className="p-0 self-end text-red-400"
										loading={isDeleteInProgress}
										icon={<DeleteFilled />}
									>
										<span className="hidden sm:inline">
											{LL.deleteProjectButton()}
										</span>
									</Button>
								</Popconfirm>
							)}
						</div>

						<Divider />

						<div className="grid grid-cols-1 md:grid-cols-2 flex-1 2xl:grid-cols-4 gap-4">
							<div className="flex h-full flex-col gap-4">
								<div>
									<Label icon={<TagOutlined />}>{LL.projectTitle()}</Label>

									<Controller
										name="name"
										control={control}
										render={({ field, fieldState: { invalid, error } }) => (
											<Form.Item
												validateStatus={invalid ? "error" : "success"}
												help={error?.message ?? ""}
											>
												<Input
													size="middle"
													type="text"
													{...field}
													disabled={!canEdit() || isFormLoading}
												/>
											</Form.Item>
										)}
									/>
								</div>

								<div className="grid grid-cols-2 gap-3">
									<CheckboxGroupModule
										label={LL.establishments()}
										disabled={!canEdit() || isFormLoading}
										cardClassName="rounded-sm max-h-[150px]"
										labelProps={{
											icon: <PlusCircleOutlined />,
										}}
										options={establishmentCheckboxes}
										value={formData.establishments}
										onChange={(value) => {
											setValue(
												"establishments",
												value as EstablishmentCheckboxValue[],
												{
													shouldDirty: true,
												},
											);
										}}
									/>

									<CheckboxGroupModule
										isExtendable={isAdmin}
										disabled={!canEdit() || isFormLoading}
										cardClassName="@container rounded-sm max-h-[150px]"
										labelProps={{
											icon: (
												<MenuOutlined className="self-center min-h-[13px]" />
											),
										}}
										addButton={{
											text: LL.addOptionButton(),
											onClick: () => {
												handleOpenProjectTypesSettings();
											},
										}}
										label={LL.projectType()}
										options={projectTypesCheckboxes}
										value={formData.projectTypes}
										onChange={(value) => {
											setValue(
												"projectTypes",
												value as ProjectTypeCheckboxValue[],
												{
													shouldDirty: true,
												},
											);
										}}
									/>
								</div>

								<div className="grid grid-cols-2 gap-3">
									<CheckboxGroupModule
										isExtendable={isAdmin}
										disabled={!canEdit() || isFormLoading}
										label={LL.financing()}
										cardClassName="rounded-sm max-h-[150px]"
										labelProps={{
											icon: <MoneyOutlined className="self-center" />,
										}}
										addButton={{
											text: LL.addOptionButton(),
											onClick: () => {
												handleOpenFinancessSettings();
											},
										}}
										options={financeTypesCheckboxes}
										value={formData.finances}
										onChange={(value) => {
											setValue("finances", value as FinanceCheckboxValue[], {
												shouldDirty: true,
											});
										}}
									/>

									<div className="flex flex-col">
										<Label icon={<CalendarOutlined />}>{LL.dueDate()}</Label>

										<Controller
											name="dueDate"
											control={control}
											render={({
												field: { name, onBlur },
												fieldState: { invalid, error },
											}) => (
												<Form.Item
													validateStatus={invalid ? "error" : "success"}
													help={error?.message ?? ""}
												>
													<DatePicker
														locale={locale}
														name={name}
														disabled={!canEdit() || isFormLoading}
														disabledDate={(date) => date.toDate() < new Date()}
														onChange={(value) => {
															setValue("dueDate", value?.toISOString(), {
																shouldDirty: true,
															});
														}}
														defaultValue={
															project.dueDate
																? dayjs(project.dueDate)
																: undefined
														}
														onBlur={onBlur}
														className="w-full"
													/>
												</Form.Item>
											)}
										/>
									</div>
								</div>

								<div className="flex flex-1 flex-col">
									<Label icon={<PenAndPaperOutlined className="self-center" />}>
										{LL.description.label()}
									</Label>

									<Controller
										name="description"
										control={control}
										render={({ field: { value, ...field } }) => (
											<Input.TextArea
												className="h-full flex-1"
												placeholder={LL.description.placeholder()}
												value={value ?? ""}
												{...field}
												disabled={!canEdit() || isFormLoading}
											/>
										)}
									/>
								</div>

								<div className="grid grid-cols-2 gap-3">
									<StateToggle
										cardClassName="pt-3 pl-3"
										defaultChecked={initialValues.active}
										disabled={!canEdit() || isFormLoading}
										onChange={(value) => {
											setValue("active", value, {
												shouldDirty: true,
											});
										}}
									/>

									<div className="flex flex-col">
										<Label icon={<HourglassOutlined />}>{LL.stage()}</Label>

										<Controller
											name="stage"
											control={control}
											render={({ field }) => (
												<Select
													options={stageOptions()}
													placeholder="-"
													{...field}
													onChange={(value) => {
														setValue("stage", value, {
															shouldDirty: true,
														});
													}}
													disabled={!canEdit() || isFormLoading}
												/>
											)}
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-col">
								<div className="@container flex flex-col">
									<div className="@3xs:flex-row flex flex-col items-center justify-between">
										<Label icon={<UserOutlined />}>{LL.users.leader()}</Label>

										{isAdmin && (
											<Button
												type="link"
												className="@3xs:self-auto self-start text-xs text-[#a2a2a2]"
												onClick={() => {
													setCurrentTab("users");
													toggleDrawer();
												}}
											>
												<span className="underline">
													{LL.addOptionButton()}
												</span>
												+
											</Button>
										)}
									</div>

									<Controller
										name="usersRoles.leader"
										control={control}
										render={({ field: { onChange: _onChange, ...field } }) => (
											<Select
												options={usersOptions(USER_PROJECT_ROLES.LEADER)}
												placeholder="-"
												onChange={async (value) => {
													setValue("usersRoles.leader", value);
													// FIXME: connect to BE enums
													await mutateProjectUsersRole({
														userId: value,
														role: USER_PROJECT_ROLES.LEADER,
													});
												}}
												{...field}
												disabled={!canEdit() || isFormLoading}
											/>
										)}
									/>
								</div>

								<div className="@container flex flex-col">
									<div className="@3xs:flex-row flex flex-col items-center justify-between">
										<Label icon={<UserOutlined />}>{LL.users.referent()}</Label>

										{isAdmin && (
											<Button
												type="link"
												className="@3xs:self-auto self-start text-xs text-[#a2a2a2]"
												onClick={() => {
													setCurrentTab("users");
													toggleDrawer();
												}}
											>
												<span className="underline">
													{LL.addOptionButton()}
												</span>
												+
											</Button>
										)}
									</div>

									<Controller
										name="usersRoles.referent"
										control={control}
										render={({ field: { onChange: _onChange, ...field } }) => (
											<Select
												options={usersOptions(USER_PROJECT_ROLES.REFERENT)}
												placeholder="-"
												onChange={async (value) => {
													setValue("usersRoles.referent", value);
													// FIXME: connect to BE enums
													await mutateProjectUsersRole({
														userId: value,
														role: USER_PROJECT_ROLES.REFERENT,
													});
												}}
												{...field}
												disabled={!canEdit() || isFormLoading}
											/>
										)}
									/>
								</div>

								<div className="@container flex flex-col">
									<div className="@3xs:flex-row flex flex-col items-center justify-between">
										<Label icon={<UserOutlined />}>
											{LL.users.contractor()}
										</Label>

										{isAdmin && (
											<Button
												type="link"
												className="@3xs:self-auto self-start text-xs text-[#a2a2a2]"
												onClick={() => {
													setCurrentTab("contractors");
													toggleDrawer();
												}}
											>
												<span className="underline">
													{LL.addOptionButton()}
												</span>
												+
											</Button>
										)}
									</div>

									<Controller
										name="externalIntervenant"
										control={control}
										render={({ field: { onChange: _onChange, ...field } }) => (
											<Select
												options={externalUsersOptions()}
												placeholder="-"
												onChange={(value) => {
													setValue("externalIntervenant", value);
													mutateProjectExternalUsers({
														userId: value,
													});
												}}
												{...field}
												disabled={!canEdit() || isFormLoading}
											/>
										)}
									/>
								</div>

								<div className="@container flex flex-col">
									<div className="@3xs:flex-row flex flex-col items-center justify-between">
										<Label icon={<UserOutlined />}>
											{LL.users.referentSupervisor()}
										</Label>

										{isAdmin && (
											<Button
												type="link"
												className="@3xs:self-auto self-start text-xs text-[#a2a2a2]"
												onClick={() => {
													setCurrentTab("users");
													toggleDrawer();
												}}
											>
												<span className="underline">
													{LL.addOptionButton()}
												</span>{" "}
												+
											</Button>
										)}
									</div>

									<Controller
										name="fileReferent"
										control={control}
										render={({ field: { onChange: _onChange, ...field } }) => (
											<Select
												options={fileReferentUsersOptions()}
												placeholder="-"
												onChange={(value) => {
													setValue("fileReferent", value);
													mutateProjectFileReferent({
														userId: value,
													});
												}}
												{...field}
												disabled={!canEdit() || isFormLoading}
											/>
										)}
									/>
								</div>

								<Card
									className="@container mt-5 flex-1 border-[#343434]"
									bodyStyle={{ padding: 12 }}
								>
									<div className="@3xs:flex-row flex flex-col items-center justify-between">
										<Label icon={<BellOutlined />}>{LL.alerts.label()}</Label>

										{canEdit() && (
											<Button
												type="link"
												className="@3xs:self-auto self-start text-xs text-[#a2a2a2]"
												onClick={() => {
													handleChangeCurrentAlert(newAlert);
												}}
											>
												<span className="underline">
													{LL.alerts.createAlert()}
												</span>
												+
											</Button>
										)}
									</div>

									<div>
										{project.alerts?.map((alert) => (
											<AlertComponent
												key={alert.id}
												canEdit={canEdit()}
												className="odd:bg-[#fafafa]"
												projectId={project.id}
												alert={alert}
												handleChangeCurrentAlert={handleChangeCurrentAlert}
											/>
										))}
									</div>
								</Card>
							</div>

							<DocumentsForm
								canEdit={canEdit()}
								isLoading={isLoading}
								files={project.files}
							/>
						</div>
					</Card>
				</form>
			</Layout>

			<Modal
				destroyOnClose
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				getContainer={document.getElementById("root")!}
				width={640}
				open={currentAlert !== null}
				onCancel={() => {
					handleChangeCurrentAlert(null);
				}}
				className="pointer-events-auto"
				modalRender={() => (
					<AlertModal
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						alert={currentAlert!}
						canEdit={canEdit()}
						handleChangeCurrentAlert={handleChangeCurrentAlert}
						projectId={project.id}
						isEditFrom={currentAlert?.id !== -1}
					/>
				)}
			/>
		</ConfigProvider>
	);
}
