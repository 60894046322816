import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, Checkbox, Form, Input, Popconfirm } from "antd";
import { type CheckboxValueType } from "antd/es/checkbox/Group";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import Label from "@/components/Label";
import StateToggle from "@/components/StateToggle";
import useEstablishmentData from "@/hooks/query/useEstablishmentData";
import { useI18nContext } from "@/i18n/i18n-react";
import {
	type User,
	UserExternal,
	UserForm,
	userFormScheme,
} from "@/utils/schemas/user";

import { type UsersProps } from ".";

const { Group } = Checkbox;
const { Item } = Form;

type UserFormProps = {
	user?: Partial<User | UserExternal>;
	type: UsersProps["type"];
	handleChangeSelectedUser: (user: User | null) => void;
	onSubmit: (user: UserForm) => void;
	onDelete?: (id: number) => void;
	isLoading?: boolean;
};

export default function UserFormComponent({
	user,
	type,
	handleChangeSelectedUser,
	onSubmit,
	onDelete,
	isLoading,
}: UserFormProps) {
	const {
		LL: {
			ProjectModule: { settings: LL },
			...t
		},
	} = useI18nContext();

	const initialValues = useMemo(
		() => ({
			...user,
			establishments: user?.establishments?.map((est) => est.id),
		}),
		[user],
	);

	const { control, handleSubmit, reset } = useForm<UserForm>({
		resolver: zodResolver(
			userFormScheme.extend({ name: z.string(), email: z.string().email() }),
		),
		defaultValues: initialValues,
	});

	useEffect(() => {
		reset(initialValues);
	}, [user]);

	const { data: establishments } = useEstablishmentData({ type });
	const establishmentsCheckboxes = useMemo(
		() =>
			establishments?.map(({ name, id }) => ({
				value: id,
				label: name,
			})),
		[establishments],
	);

	const onError = (e: any) => {
		console.error(e);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit, onError)}>
			<Card
				bordered={false}
				className="rounded-none bg-[#fafafa]"
				bodyStyle={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<CloseOutlined
					className="self-end cursor-pointer mt-2"
					onClick={() => {
						handleChangeSelectedUser(null);
					}}
				/>

				<div className="grid grid-cols-2 mb-2 gap-3">
					<div>
						<Label className="m-0 mb-2" required>{LL.tabs.users.name()}</Label>
						<Controller
							name="name"
							control={control}
							render={({ field, fieldState: { invalid, error } }) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
									required
								>
									<Input {...field} />
								</Item>
							)}
						/>

						<Label className="m-0 mb-2">{LL.tabs.users.surname()}</Label>
						<Controller
							name="lastName"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>

						<Label className="m-0 mb-2">{LL.tabs.users.function()}</Label>
						<Controller
							name="function"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>

					<div className="flex flex-col">
						<Label className="m-0 mb-2">{LL.tabs.establishments.label()}</Label>

						<Card
							className="rounded-sm overflow-y-auto flex-1"
							bodyStyle={{
								height: "100%",
								paddingTop: 5,
								flex: "1 1 0%",
								paddingBottom: 0,
							}}
						>
							<div className="flex flex-row border-b-black border-b mb-3">
								<Input
									bordered={false}
									className="px-0"
									placeholder={LL.tabs.search()}
								/>

								<SearchOutlined />
							</div>
							<Controller
								name="establishments"
								control={control}
								render={({ field, fieldState: { invalid, error } }) => (
									<Item
										validateStatus={invalid ? "error" : "success"}
										help={error?.message ?? ""}
										required={false}
									>
										<Group
											onChange={(data) => {
												field.onChange(data);
											}}
											value={field.value as CheckboxValueType[]}
											className="flex-col"
											options={establishmentsCheckboxes}
										/>
									</Item>
								)}
							/>
						</Card>
					</div>
				</div>

				<div className="grid gap-3 grid-cols-2 grid-rows-2">
					<div>
						<Label className="m-0 mb-2">
							{LL.tabs.users.phoneNumberStationary()}
						</Label>
						<Controller
							name="telephone"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>

					<div>
						<Label className="m-0 mb-2">{LL.tabs.users.phoneNumber()}</Label>
						<Controller
							name="mobile"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>

					<div>
						<Label className="m-0 mb-2" required>{LL.tabs.users.email()}</Label>
						<Controller
							name="email"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error, ...state },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>

					<Controller
						name="active"
						control={control}
						render={({
							field: { value, onChange },
							fieldState: { invalid, error },
						}) => (
							<Item
								validateStatus={invalid ? "error" : "success"}
								help={error?.message ?? ""}
							>
								<StateToggle checked={!!value} onChange={onChange} />
							</Item>
						)}
					/>
				</div>

				<div className={"mt-5 flex row align-center justify-end"}>
					{onDelete && !!user?.id && (
						<Popconfirm
							title={t.popovers.deleteItemConfirm()}
							onConfirm={() => {
								user.id && onDelete(user.id);
							}}
							okText={t.buttons.yes()}
							cancelText={t.buttons.no()}
						>
							<Button
								type={"text"}
								color={"error"}
								className={"mr-2"}
								loading={isLoading}
							>
								{LL.delete()}
							</Button>
						</Popconfirm>
					)}

					<Button
						htmlType={"submit"}
						className="mr-0border-none bg-black text-white"
						loading={isLoading}
					>
						{LL.update()}
					</Button>
				</div>
			</Card>
		</form>
	);
}
