import { CloseOutlined, FormOutlined } from "@ant-design/icons";
import { Card, Divider, Typography } from "antd";
import { useContext, useEffect, useState } from "react";

import { DrawerContext } from "@/context";
import useUserData from "@/hooks/query/useUserData";
import { useI18nContext } from "@/i18n/i18n-react";
import { User } from "@/utils/schemas/user";

import RolesForm from "./RolesForm";

export default function Global() {
	const {
		LL: {
			ProjectModule: { settings: LL },
		},
	} = useI18nContext();

	const { data: users } = useUserData(null, {
		type: "internal",
		onlyUsersWithAccounts: true,
	});

	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	function handleChangeSelectedUser(user: User | null) {
		setSelectedUser(user);
	}

	const { closeDrawer } = useContext(DrawerContext);

	useEffect(() => {
		const newUser = users?.find((user) => selectedUser?.id === user.id) ?? null;

		handleChangeSelectedUser(newUser);
	}, [users]);

	return (
		<>
			<Typography className="flex justify-between">
				<Typography.Title level={2} className="mb-0">
					{LL.title()}
				</Typography.Title>

				<CloseOutlined className="cursor-pointer" onClick={closeDrawer} />
			</Typography>

			<Divider className="my-2" />

			<Card
				className="max-h-[380px] min-h-[380px] mb-10 border-[#343434] overflow-y-auto"
				bodyStyle={{
					padding: 12,
					display: "flex",
					flexDirection: "column",
					justifyItems: "center",
					alignItems: "center",
				}}
			>
				{users?.map((user) => (
					<div
						key={user.id}
						className="cursor-pointer flex w-full flex-row items-center justify-between even:bg-[#fafafa] py-5 pl-4 pr-3"
						onClick={() => {
							handleChangeSelectedUser(user);
						}}
					>
						<div className="flex flex-col">
							<span className="font-bold">
								{user.name} {user.lastName}
							</span>

							<span className="max-w-[35ch] whitespace-nowrap overflow-hidden text-ellipsis text-[#a2a2a2]">
								{user.establishments?.map(
									({ name }, index, arr) =>
										`${name}${index !== arr.length - 1 ? ", " : ""}`,
								)}
							</span>
						</div>

						<FormOutlined
							className="cursor-pointer text-lg text-[#5e9ad3]"
						/>
					</div>
				))}
			</Card>

			{selectedUser ? (
				<RolesForm
					user={selectedUser}
					handleChangeUser={handleChangeSelectedUser}
				/>
			) : null}
		</>
	);
}
