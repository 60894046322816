import {
	BellOutlined,
	CloseOutlined,
	DeleteFilled,
	DragOutlined,
	EyeFilled,
	PaperClipOutlined,
} from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import {
	Button,
	Card,
	Checkbox,
	DatePicker,
	Form,
	Input,
	Select,
	TimePicker,
	Typography,
	Upload,
} from "antd";
import { type CheckboxOptionType } from "antd/es/checkbox/Group";
import locale from "antd/es/date-picker/locale/fr_FR";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import {
	Controller,
	SubmitErrorHandler,
	SubmitHandler,
	useForm,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { queryClient } from "@/App";
import { deleteFile } from "@/api";
import Label from "@/components/Label";
import { useToast } from "@/context";
import { env } from "@/env";
import { createAlertQuery, patchAlertQuery } from "@/hooks/query/useAlertData";
import { projectQuery } from "@/hooks/query/useProjectData";
import useUserData from "@/hooks/query/useUserData";
import { useI18nContext } from "@/i18n/i18n-react";
import alert, { type Alert, AlertAttachment } from "@/utils/schemas/alert";
import whatHasChangedInArray from "@/utils/whatHasChangedInArray";

const { TextArea } = Input;
const { Group } = Checkbox;
const { Paragraph } = Typography;
const { Item } = Form;

const paramsSchema = z.object({
	// Regex which checks if the string contains only numbers
	id: z.string().regex(/^[0-9]+$/),
});

const createAlertForm = alert.omit({ id: true, alertRepeatings: true }).extend({
	title: z.string().min(1),
	alertRepeatings: z.array(
		z.enum([
			"10-1",
			"10-2",
			"10-5",
			"20-1",
			"20-2",
			"20-3",
			"30-1",
			"30-2",
			"30-3",
		]),
	),
	users: z.array(z.number()).min(1),
	file: z.custom<File>().optional(),
});
export type CreateAlertForm = z.infer<typeof createAlertForm>;

export type ModifyAlertForm = Omit<
	CreateAlertForm,
	"users" | "alertRepeatings"
> & {
	users: {
		added: CreateAlertForm["users"];
		deleted: CreateAlertForm["users"];
	};
	alertRepeatings: {
		added: CreateAlertForm["alertRepeatings"];
		deleted: CreateAlertForm["alertRepeatings"];
	};
};

type RepeatingsCheckbox = {
	value: CreateAlertForm["alertRepeatings"][number];
	label: string;
};

const checkboxes: RepeatingsCheckbox[] = [
	{ value: "10-1" as const, label: "J-1" },
	{ value: "10-2" as const, label: "J-2" },
	{ value: "10-5" as const, label: "J-5" },

	{ value: "20-1" as const, label: "S-1" },
	{ value: "20-2" as const, label: "S-2" },
	{ value: "20-3" as const, label: "S-3" },

	{ value: "30-1" as const, label: "M-1" },
	{ value: "30-2" as const, label: "M-2" },
	{ value: "30-3" as const, label: "M-3" },
] satisfies CheckboxOptionType[];

type AlertModalProps = {
	alert: Alert;
	isEditFrom: boolean;
	projectId: number;
	handleChangeCurrentAlert: (alrt: Alert | null) => void;
	canEdit: boolean;
};

export default function AlertModal({
	alert,
	isEditFrom,
	projectId,
	handleChangeCurrentAlert,
	canEdit,
}: AlertModalProps) {
	const params = paramsSchema.parse(useParams());

	const {
		LL: {
			ProjectModule: {
				projectForm: { alerts: LL },
			},
			toasts: ToastLL,
		},
	} = useI18nContext();

	const { toast } = useToast();

	const { data: users } = useUserData(null, {
		type: "internal",
		projectId: params.id,
	});
	const usersOptions = useMemo(
		() =>
			users?.map(({ id, name, lastName }) => ({
				label: `${name} ${lastName}`,
				value: id,
			})),
		[users],
	);

	const { mutate: createAlert, isLoading: isCreateAlertMutating } = useMutation(
		{
			...createAlertQuery(),
			onSuccess: async () => {
				await queryClient.invalidateQueries();
				handleChangeCurrentAlert(null);
				toast.success({
					message: ToastLL.success(),
					description: ToastLL.createdSuccessfully(),
				});
			},
			onError: (e: any) => {
				console.error(e);
				toast.error({
					message: ToastLL.error(),
					description: e.message ?? ToastLL.somethingIsNoYes(),
				});
			},
		},
	);

	const { mutate: patchAlert, isLoading: isPatchAlertMutating } = useMutation({
		...patchAlertQuery(alert.id),
		onSuccess: async () => {
			await queryClient.invalidateQueries();
			handleChangeCurrentAlert(null);
			toast.success({
				message: ToastLL.success(),
				description: ToastLL.updatedSuccessfully(),
			});
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	const initialValues: CreateAlertForm = {
		title: alert.title,
		triggerDate: alert.triggerDate,
		hourOfReminder: alert.hourOfReminder,
		alertRepeatings: (alert.alertRepeatings?.map(
			(repeat) => `${repeat.alertRepeating}-${repeat.unitNumber}`,
		) ?? []) as CreateAlertForm["alertRepeatings"],
		description: alert.description,
		notifyViaEmail: alert.notifyViaEmail,
		users: alert.users?.map(({ id }) => id) ?? [],
		attachment: alert.attachment,
	};

	const { control, handleSubmit, setValue, getValues, watch } =
		useForm<CreateAlertForm>({
			defaultValues: initialValues,
			resolver: zodResolver(createAlertForm),
		});

	const fileWatch = watch("file");

	const onValid: SubmitHandler<CreateAlertForm> = () => {
		const data = getValues();

		// Create new Date that takes triggerDate as Date and hours from hourOfReminder
		const triggerDate = new Date(data.triggerDate);
		const hourOfReminder = new Date(data.hourOfReminder);

		const date = dayjs(data.triggerDate);
		const time = date
			.set("hours", hourOfReminder.getHours())
			.set("minutes", hourOfReminder.getMinutes());

		const parsedData = {
			...data,
			triggerDate: date.toISOString(),
			hourOfReminder: time.toISOString(),
		};

		if (!isEditFrom) createAlert([parsedData, projectId]);
		else {
			const { users, alertRepeatings } = parsedData;

			const parsedUsers = whatHasChangedInArray(users, initialValues.users);
			const parsedAlertRepeatings = whatHasChangedInArray(
				alertRepeatings,
				initialValues.alertRepeatings,
			);

			patchAlert({
				...parsedData,
				users: parsedUsers,
				alertRepeatings: parsedAlertRepeatings,
			});
		}
	};

	const onInvalid: SubmitErrorHandler<CreateAlertForm> = (data) => {
		console.error(data);
	};

	const onFileOpen = (path: string) =>
		open(`${env.VITE_PUBLIC_MEDIA_URL}${path}`);

	const onFileRemove = async (fileId?: number) => {
		setValue("attachment", null);
		setValue("file", undefined);
		if (fileId) {
			await deleteFile(fileId);
			await queryClient.invalidateQueries();
		}
	};

	const showFile = (file: any) => {
		return (
			<div key={file.id} className="flex flex-row items-center hover:bg-white">
				<div className="flex flex-1 flex-row gap-2 p-3">
					<p className="m-0">{file.name}</p>
				</div>

				<div className="flex flex-row gap-2 pr-3 text-[#5e9ad3]">
					{file.path && (
						<Button
							className={"p-0"}
							type={"text"}
							onClick={() => {
								onFileOpen(file.path);
							}}
							icon={
								<EyeFilled
									color={"#5e9ad3"}
									className="cursor-pointer text-[#5e9ad3]"
								/>
							}
						/>
					)}

					{canEdit && (
						<Button
							className={"p-0"}
							type={"text"}
							onClick={() => onFileRemove(file.id)}
							icon={
								<DeleteFilled
									color={"#5e9ad3"}
									className="cursor-pointer text-[#5e9ad3]"
								/>
							}
						/>
					)}
				</div>
			</div>
		);
	};

	return (
		<Card
			className="min-h-[475px] border-none overflow-hidden"
			bodyStyle={{ padding: 0 }}
		>
			<form onSubmit={handleSubmit(onValid, onInvalid)}>
				<div className="flex justify-between bg-black text-white p-5">
					<div>
						<BellOutlined className="mr-2" />

						<span>{isEditFrom ? LL.modifyAlert() : LL.createAlert()}</span>
					</div>

					<CloseOutlined
						className="cursor-pointer"
						onClick={() => {
							handleChangeCurrentAlert(null);
						}}
					/>
				</div>

				<div className="grid grid-cols-2 p-5 pb-0 gap-2">
					<div className="flex flex-col">
						<div>
							<Label className="ml-0">{LL.form.date()}</Label>

							<Controller
								name="triggerDate"
								control={control}
								render={({
									field: { name, onBlur, onChange },
									fieldState: { invalid, error },
								}) => (
									<Item
										validateStatus={invalid ? "error" : "success"}
										help={error?.message ?? ""}
									>
										<DatePicker
											className="w-full"
											name={name}
											locale={locale}
											onBlur={onBlur}
											placeholder={LL.form.datePlaceholder()}
											disabledDate={(date) => date.toDate() < new Date()}
											defaultValue={
												alert.triggerDate.length
													? dayjs(alert.triggerDate)
													: undefined
											}
											onChange={(value) => {
												onChange(value?.toISOString() ?? "");
											}}
										/>
									</Item>
								)}
							/>
						</div>

						<div>
							<Label className="ml-0">{LL.form.alertName()}</Label>

							<Controller
								name="title"
								control={control}
								disabled={!canEdit}
								render={({ field, fieldState: { error, invalid } }) => (
									<Item
										validateStatus={invalid ? "error" : "success"}
										help={error?.message ?? ""}
									>
										<Input
											placeholder={LL.form.alertPlaceholder()}
											{...field}
										/>
									</Item>
								)}
							/>
						</div>

						<div className="flex-1 flex flex-col">
							<Label className="ml-0">{LL.form.additionalInformation()}</Label>

							<Controller
								name="description"
								control={control}
								disabled={!canEdit}
								render={({ field: { value, ...field } }) => (
									<TextArea
										placeholder={LL.form.additionalInformationPlaceholder()}
										className="flex-1"
										value={value ?? ""}
										{...field}
									/>
								)}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-2">
						<div className="flex flex-col">
							<Label className="ml-0">{LL.form.receivers()}</Label>

							<Controller
								name="users"
								control={control}
								disabled={!canEdit}
								render={({
									field: { onChange: _onChange, ...field },
									fieldState: { invalid, error },
								}) => (
									<Form.Item
										validateStatus={invalid ? "error" : "success"}
										help={error?.message ?? ""}
									>
										<Select
											className="mb-2"
											mode="multiple"
											options={usersOptions}
											onChange={(value) => {
												setValue("users", value);
											}}
											{...field}
										/>
									</Form.Item>
								)}
							/>

							<Checkbox
								defaultChecked={alert.notifyViaEmail}
								disabled={!canEdit}
								onChange={(e) => {
									setValue("notifyViaEmail", e.target.checked);
								}}
							>
								{LL.form.emailAlertCheckbox()}
							</Checkbox>
						</div>

						<div>
							<Typography>
								<Paragraph className="ml-0 mb-0 font-bold">
									{LL.form.reminder()}
								</Paragraph>

								<Paragraph className="ml-0 text-[#8b8b8b]">
									{LL.form.sendReminderTo()}
								</Paragraph>
							</Typography>

							<Group
								className="grid grid-cols-3 grid-rows-3 gap-2"
								options={checkboxes}
								disabled={!canEdit}
								defaultValue={initialValues.alertRepeatings}
								onChange={(value) => {
									setValue(
										"alertRepeatings",
										value as CreateAlertForm["alertRepeatings"],
									);
								}}
							/>
						</div>

						<div className="flex flex-col">
							<Label className="ml-0 text-[#a2a2a2]">{LL.form.time()}</Label>

							<Controller
								name="hourOfReminder"
								control={control}
								disabled={!canEdit}
								render={({
									field: { onChange: _onChange, value: _value, ...field },
									fieldState: { invalid, error },
								}) => (
									<Item
										validateStatus={invalid ? "error" : "success"}
										help={error?.message ?? ""}
									>
										<TimePicker
											placeholder="12:00"
											hideDisabledOptions
											format={"HH:mm"}
											showNow={false}
											disabled={!canEdit}
											defaultValue={
												alert.hourOfReminder.length
													? dayjs(alert.hourOfReminder)
													: dayjs().set("hours", 12).set("minutes", 0)
											}
											onChange={(value) => {
												setValue("hourOfReminder", value?.toISOString() ?? "");
											}}
											{...field}
										/>
									</Item>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="flex justify-between p-3">
					<Upload
						beforeUpload={() => false}
						multiple={false}
						disabled={!canEdit}
						showUploadList={false}
						onChange={({ file }) => {
							if (file.status !== "uploading")
								setValue("file", (file.originFileObj ?? file) as File);
						}}
					>
						<div className="flex items-center cursor-pointer">
							{!fileWatch && !getValues("attachment") && canEdit && (
								<PaperClipOutlined className="text-black text-base" />
							)}

							{!!fileWatch && showFile(fileWatch)}

							{getValues("attachment") && showFile(getValues("attachment"))}

							{!fileWatch && !getValues("attachment") && canEdit && (
								<Button type="link" className="p-0">
									<span className="text-black underline ml-0">
										{LL.form.attachment()}
									</span>
								</Button>
							)}
						</div>
					</Upload>

					{canEdit && (
						<Button
							type="primary"
							htmlType="submit"
							className="self-end px-8 h-9 bg-[#ff9043]"
							loading={isPatchAlertMutating || isCreateAlertMutating}
						>
							{isEditFrom ? LL.form.modify() : LL.form.create()}
						</Button>
					)}
				</div>
			</form>
		</Card>
	);
}
