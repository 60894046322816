import {
	UseMutationOptions,
	type UseQueryOptions,
	useQuery,
} from "@tanstack/react-query";

import { deleteFile, uploadFile } from "@/api";
import {
	type CreateAlertForm,
	ModifyAlertForm,
} from "@/components/project-list/modify-project/AlertModal";
import { fetchClient } from "@/definitions";
import alert, { type Alert } from "@/utils/schemas/alert";

export function alertQuery(id: number) {
	return {
		queryKey: ["alert", id],
		queryFn: async () => {
			const res = await fetchClient(`Alerts/${id}`);

			return alert.parse(res);
		},
	} satisfies UseQueryOptions;
}

export function deleteAlertQuery(id: number) {
	return {
		mutationKey: ["alert", "delete", id],
		mutationFn: async () => {
			await fetchClient(`Alerts/${id}`, {
				method: "DELETE",
			});

			return null;
		},
	} satisfies UseMutationOptions;
}

export function createAlertQuery() {
	return {
		mutationKey: ["alert"],
		mutationFn: async ([data, projectId]) => {
			// Create alert request
			const createAlertData = (await fetchClient(`Alerts/`, {
				method: "POST",
				body: JSON.stringify(data),
			})) as Alert;

			// Request for adding Alert to a project
			const patchProjectResponse = fetchClient(
				`Projects/${projectId}/Alert/${createAlertData.id}`,
				{
					method: "PATCH",
				},
			);

			// Requests for adding Alerts to users
			const patchUsersResponses = data.users.map((id) =>
				fetchClient(`Users/${id}/Alert/${createAlertData.id}`, {
					method: "PATCH",
				}),
			);

			// Requests for creating Reminders
			const postRemindersResponses = data.alertRepeatings.map((repeating) => {
				const [alertRepeating, unitNumber] = repeating.split("-");
				const body = {
					alertRepeating,
					unitNumber,
				};

				return fetchClient(`Alerts/${createAlertData.id}/Reminder`, {
					method: "POST",
					body: JSON.stringify(body),
				});
			});

			// Is attachemenet being added
			if (data.file) {
				await uploadFile(data.file, {
					entity: "Alert",
					entityId: createAlertData.id.toString(),
					fileType: 100,
				});
			}

			// Making the requests concurrently and awaiting them
			await Promise.all([
				patchProjectResponse,
				...patchUsersResponses,
				...postRemindersResponses,
			]);

			return createAlertData;
		},
	} satisfies UseMutationOptions<
		Alert | null,
		unknown,
		[CreateAlertForm, number]
	>;
}

export function patchAlertQuery(id: number) {
	return {
		mutationKey: ["alert", "patch", id],
		mutationFn: async (data) => {
			const body = { ...data };

			delete body.file;
			// Patch alert request
			const newAlertData = (await fetchClient(`Alerts/${id}`, {
				method: "PATCH",
				body: JSON.stringify(body),
			})) as Alert;

			// Requests for updating users
			const patchUsersResponses = data.users.added.map((id) =>
				fetchClient(`Users/${id}/Alert/${newAlertData.id}`, {
					method: "PATCH",
				}),
			);

			const deleteUsersResponses = data.users.deleted.map((id) =>
				fetchClient(`Users/${id}/Alert/${newAlertData.id}`, {
					method: "DELETE",
				}),
			);

			// Requests for updating Reminders
			const postRemindersResponses = data.alertRepeatings.added.map(
				(repeating) => {
					const [alertRepeating, unitNumber] = repeating.split("-");
					const body = {
						alertRepeating,
						unitNumber,
					};

					return fetchClient(`Alerts/${newAlertData.id}/Reminder`, {
						method: "POST",
						body: JSON.stringify(body),
					});
				},
			);

			// Based on the form data, get ids of each reminder
			const deleteReminderResponses = data.alertRepeatings.deleted
				.reduce((acc: number[], repeating) => {
					const [alertRepeating, unitNumber] = repeating.split("-");

					const reminderId = newAlertData.alertRepeatings?.find((value) => {
						return (
							value.unitNumber.toString() === unitNumber &&
							value.alertRepeating.toString() === alertRepeating
						);
					})?.id;

					if (reminderId) acc.push(reminderId);
					return acc;
				}, [])
				// Map on found ids to make delete requests
				.map((reminderId) =>
					fetchClient(`Alerts/Reminder/${reminderId}`, {
						method: "DELETE",
					}),
				);

			if (data.file) {
				// remove old attachment if exists
				if (newAlertData.attachment) {
					await deleteFile(newAlertData.attachment.id);
				}
				await uploadFile(data.file, {
					entity: "Alert",
					entityId: newAlertData.id.toString(),
					fileType: 100,
				});
			}

			// Making the requests concurrently and awaiting them
			await Promise.all([
				...patchUsersResponses,
				...deleteUsersResponses,
				...postRemindersResponses,
				...deleteReminderResponses,
			]);

			return newAlertData;
		},
	} satisfies UseMutationOptions<Alert | null, unknown, ModifyAlertForm>;
}

export default function useAlertData(id: number) {
	return useQuery(alertQuery(id));
}
