export const USER_ROLES = {
	SUPER_ADMIN: 0,
	MANAGER: 1,
	INTERNAL_INTERVENER: 2,
} as const;

export const USER_PROJECT_ROLES = {
	TUTOR: 0,
	REFERENT: 1,
	LEADER: 2,
} as const;

export const userProjectRolesArray = Object.entries(USER_PROJECT_ROLES).reduce(
	(acc: any[], [key, value]) => {
		acc[value] = key.toLowerCase();
		return acc;
	},
	[],
);

export const USER_ESTABLISHMENT_ROLES = {
	READER: 0,
	READER_WRITER: 1,
} as const;
