import { z } from "zod";

import establishment, { externalEstablishment } from "./establishment";

export type UserType = "internal" | "external";

const establishmentAccess = establishment
	.pick({
		id: true,
		name: true,
		active: true,
		created: true,
		updated: true,
	})
	.extend({
		accessLevel: z.number().optional(),
	});

export const userFormScheme = z.object({
	name: z.string().optional(),
	lastName: z.string().nullish(),
	function: z.string().nullish(),
	mobile: z.string().nullish(),
	telephone: z.string().nullish(),
	email: z.string().nullish(),
	created: z.string().optional(),
	updated: z.string().optional(),
	establishments: z.array(z.number()).optional(),
	active: z.boolean().optional().nullish()
});

const baseUser = z.object({
	id: z.number(),
	name: z.string().optional(),
	lastName: z.string().nullish(),
	function: z.string().nullish(),
	mobile: z.string().nullish(),
	telephone: z.string().nullish(),
	email: z.string().nullish(),
	created: z.string().optional(),
	updated: z.string().optional(),
	active: z.boolean().optional().nullish()
});

export const externalUser = baseUser.extend({
	establishments: z.array(externalEstablishment).nullish(),
});

const user = baseUser.extend({
	adId: z.string().uuid().nullish(),
	establishments: z.array(establishmentAccess).nullish(),
	role: z
		.object({
			value: z.number(),
			label: z.string().nullable(),
		})
		.optional(),
	email: z.string().nullish(),
});

export const loggedInUser = z.object({
	bearerToken: z.string(),
	user: user,
});

export default user;

export type User = z.infer<typeof user>;
export type LoggedInUser = z.infer<typeof loggedInUser>;
export type UserExternal = Omit<User, "adId">;
export type UserForm = z.infer<typeof userFormScheme>;
