import { Button, Card, Layout, Space, Typography } from "antd";
import { type ReactNode, useMemo } from "react";
import {Navigate, useNavigate} from "react-router-dom";

import { ReactComponent as Clipboard } from "@/assets/icons/clipboard.svg";
import { ReactComponent as HeartBeat } from "@/assets/icons/heartbeat.svg";
import { ReactComponent as SmartContracts } from "@/assets/icons/smart-contracts.svg";
import { useI18nContext } from "@/i18n/i18n-react";
import cn from "@/utils/cn";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

type Module = {
	icon: ReactNode;
	title: string;
	content: string;
	disabled: boolean;
};

export default function Home() {
	const { LL } = useI18nContext();
	const navigate = useNavigate();

	return <Navigate to="/projects" />;

	const modules: Module[] = useMemo(
		() => [
			{
				icon: <SmartContracts className="h-12 w-14" />,
				title: LL.NavBar.projectModule.title(),
				content:
					"Ce module est utilisé pour créer et superviser de nouveaux projets entre les parties intéressées.",
				disabled: false,
			},
			{
				icon: <HeartBeat className="h-12 w-14" />,
				title: "Module lorem ipsum dolor",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat placerat nibh malesuada rhoncus. Donec vel suscipit tellus, a lobortis dolor.",
				disabled: true,
			},
			{
				icon: <Clipboard className="h-12 w-14" />,
				title: "Module loremip",
				content:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat placerat nibh malesuada rhoncus. Donec vel suscipit tellus, a lobortis dolor.",
				disabled: true,
			},
		],
		[],
	);

	function handleRedirect() {
		navigate("/projects");
	}

	return (
		<Content className="flex flex-col items-center justify-center gap-10">
			<Typography>
				<Title className="font-light text-[#bababa]">
					{LL.Home.selectModule()}
				</Title>
			</Typography>

			<Space size="large">
				{modules.map(({ title, content, icon, disabled }) => (
					<Card
						key={title}
						bordered={disabled}
						className={cn(
							"flex min-h-[380px] max-w-xs rounded-2xl drop-shadow-md",
							disabled ? "border-[#d5d5d5] opacity-50 drop-shadow-none" : "",
						)}
						bodyStyle={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div className="flex-1">
							{icon}

							<Typography className="mt-5">
								<Title
									level={3}
									className={cn(disabled ? "text-[#8b8b8b]" : "")}
								>
									{title}
								</Title>

								<Paragraph className="font-light text-[#8b8b8b]">
									{content}
								</Paragraph>
							</Typography>
						</div>

						<Button
							type="default"
							className="self-start justify-self-end border-none bg-[#ff9043] text-white shadow-none hover:border-none hover:text-white disabled:bg-[#d5d5d5]"
							disabled={disabled}
							onClick={handleRedirect}
						>
							{LL.Home.access()}
						</Button>
					</Card>
				))}
			</Space>
		</Content>
	);
}
