import { LoginFormInputs } from "@/components/login-form/LoginForm";
import { fetchClient } from "@/definitions";
import user, { UserForm, UserType, loggedInUser } from "@/utils/schemas/user";

export const logIn = async (data: LoginFormInputs) => {
	const res = await fetchClient(`Users/Authenticate`, {
		method: "POST",
		body: JSON.stringify(data),
	});

	return loggedInUser.parse(res);
};
export const createUser = async (data: UserForm, type: UserType) => {
	const res = await fetchClient(
		type === "external" ? "ExternalIntervenants" : "Users",
		{
			method: "POST",
			body: JSON.stringify(data),
		},
	);

	return user.parse(res);
};

export const updateUser = async (
	data: UserForm & { id: number },
	type: UserType,
) => {
	const res = await fetchClient(
		type === "external"
			? `ExternalIntervenants/${data.id}`
			: `Users/${data.id}`,
		{
			method: "PATCH",
			body: JSON.stringify(data),
		},
	);

	return user.parse(res);
};

export const deleteUser = async (id: number, type: UserType) => {
	await fetchClient(
		type === "external" ? `ExternalIntervenants/${id}` : `Users/${id}`,
		{
			method: "DELETE",
		},
	);

	return;
};

export const addUserEstablishment = async (
	data: { idUser?: number; idEstablishment: number },
	type: UserType,
) => {
	const res = await fetchClient(
		`${type === "external" ? "ExternalIntervenants" : "Users"}/${
			data.idUser
		}/Establishment/${data.idEstablishment}`,
		{
			method: "PATCH",
			body: JSON.stringify({}),
		},
	);

	return user.parse(res);
};

export const removeUserEstablishment = async (
	data: { idUser?: number; idEstablishment: number },
	type: UserType,
) => {
	await fetchClient(
		`${type === "external" ? "ExternalIntervenants" : "Users"}/${
			data.idUser
		}/Establishment/${data.idEstablishment}`,
		{
			method: "DELETE",
		},
	);

	return null;
};

export const getExternalUsers = async () => {
	const res = await fetchClient(`ExternalIntervenants`, {
		method: "GET",
	});

	return user.parse(res);
};
