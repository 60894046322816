import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	CloseOutlined,
	FormOutlined,
} from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Divider, Typography, theme } from "antd";
import { useContext, useEffect, useState } from "react";

import {
	addUserEstablishment,
	createUser,
	deleteUser,
	removeUserEstablishment,
	updateUser,
} from "@/api";
import { DrawerContext, useToast } from "@/context";
import mutationKeys from "@/definitions/mutation-keys";
import useUserData from "@/hooks/query/useUserData";
import { useI18nContext } from "@/i18n/i18n-react";
import {
	type User,
	type UserExternal,
	UserForm,
	type UserType,
} from "@/utils/schemas/user";

import UserFormComponent from "./UserForm";

const { useToken } = theme;

export type UsersProps = {
	type: UserType;
};

export default function Users({ type }: UsersProps) {
	const {
		LL: {
			ProjectModule: { settings: LL },
			toasts: ToastLL,
		},
	} = useI18nContext();
	const queryClient = useQueryClient();

	const { toast } = useToast();

	const { token } = useToken();

	const { data: users } = useUserData(null, { type });

	const [selectedUser, setSelectedUser] = useState<Partial<
		User | UserExternal
	> | null>(null);
	function handleChangeSelectedUser(user: Partial<User | UserExternal> | null) {
		setSelectedUser(user);
	}

	useEffect(() => {
		handleChangeSelectedUser(null);
	}, [type]);

	const { closeDrawer } = useContext(DrawerContext);

	const { mutateAsync: mutateUser, isLoading } = useMutation({
		mutationKey: mutationKeys.users({ type }, [selectedUser?.id]),
		mutationFn: (vars: UserForm) =>
			(selectedUser?.id
				? updateUser({ ...vars, id: selectedUser.id }, type)
				: createUser(vars, type)
			)
				.then(async (updatedUser) => {
					if (vars.establishments) {
						if (selectedUser?.establishments) {
							for (const existingEstablishment of selectedUser.establishments) {
								if (!vars.establishments.includes(existingEstablishment.id)) {
									await removeUserEstablishment(
										{
											idUser: updatedUser.id,
											idEstablishment: existingEstablishment.id,
										},
										type,
									);
								}
							}
						}
						for (const establishmentId of vars.establishments) {
							await addUserEstablishment(
								{ idUser: updatedUser.id, idEstablishment: establishmentId },
								type,
							);
						}
					}
				})
				.then(async () => {
					handleChangeSelectedUser(null);
					await queryClient.invalidateQueries();
					toast.success({
						message: ToastLL.success(),
						description: selectedUser?.id
							? ToastLL.updatedSuccessfully()
							: ToastLL.createdSuccessfully(),
					});
				})
				.catch((e) => {
					console.error(e);

					toast.error({
						message: ToastLL.error(),
						description:
							e instanceof Error ? e.message : ToastLL.somethingIsNoYes(),
					});
				}),
		retry: 0,
	});

	const handleDeleteUser = async (id: number) => {
		await deleteUser(id, type);
		handleChangeSelectedUser(null);
		await queryClient.invalidateQueries();
		toast.success({
			message: ToastLL.success(),
			description: ToastLL.deletedSuccessfully(),
		});
	};

	return (
		<>
			<Typography className="flex justify-between">
				<div className="flex flex-1 gap-5">
					<Typography.Title level={2} className="mb-0">
						{type === "internal"
							? LL.tabs.users.title()
							: LL.tabs.users.contractorsTitle()}
					</Typography.Title>

					<Button
						onClick={() => {
							handleChangeSelectedUser(null);
							setTimeout(() => {
								handleChangeSelectedUser({});
							});
						}}
						type="link"
						className="self-end p-0 text-xs text-[#a2a2a2]"
					>
						<span className="underline">{LL.addOptionButton()}</span> +
					</Button>
				</div>

				<CloseOutlined className="cursor-pointer" onClick={closeDrawer} />
			</Typography>

			<Divider className="my-2" />

			<Card
				className="mb-10 max-h-[380px] min-h-[380px] border-[#343434] overflow-y-auto"
				bodyStyle={{
					padding: 12,
					display: "flex",
					flexDirection: "column",
					justifyItems: "center",
					alignItems: "center",
				}}
			>
				{users?.map((user) => {
					const { id, name, lastName, establishments, active } = user;
					return (
						<div
							key={id}
							className="cursor-pointer flex w-full flex-row items-center justify-between even:bg-[#fafafa] py-5 pl-4 pr-3"
							onClick={() => {
								handleChangeSelectedUser(user);
							}}
						>
							<div className="flex flex-col">
								<span className="font-bold">
									<span
										style={{
											color: active
												? token.colorSuccessHover
												: token.colorErrorHover,
											marginRight: 5,
										}}
									>
										{active ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
									</span>
									{name} {lastName}
								</span>

								<span className="max-w-[35ch] whitespace-nowrap overflow-hidden text-ellipsis text-[#a2a2a2]">
									{establishments?.map(
										({ name }, index) =>
											`${name}${
												index !== establishments.length - 1 ? ", " : ""
											}`,
									)}
								</span>
							</div>

							<FormOutlined className="cursor-pointer text-lg text-[#5e9ad3]" />
						</div>
					);
				})}
			</Card>

			{selectedUser !== null ? (
				<UserFormComponent
					type={type}
					isLoading={isLoading}
					user={selectedUser}
					onDelete={handleDeleteUser}
					handleChangeSelectedUser={handleChangeSelectedUser}
					onSubmit={mutateUser}
				/>
			) : null}
		</>
	);
}
