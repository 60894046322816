export const persistenceKeys = {
	projectsListParams: "projectsListParams"
} as const

export const storageKeys = {
	...persistenceKeys,
	authToken: "authToken",
	user: "user",
} as const;

export const routeDeclarations = {
	home: "/",
	logIn: "/login",
	projects: "/projects",
	project: "/projects/:id",
} as const;

export const routes = {
	home: () => "/",
	logIn: () => "/login",
	projects: () => "/projects",
	project: (id: number | string) => `/projects/${id}`,
} as const;
