export const MEDIA_QUERIES = {
	0: "zero",
	640: "sm",
	768: "md",
	1024: "lg",
	1280: "xl",
	1536: "xxl",
} as const;

export type MediaQueriesValues =
	(typeof MEDIA_QUERIES)[keyof typeof MEDIA_QUERIES];
