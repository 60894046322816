import {
	type UseMutationOptions,
	type UseQueryOptions,
	useQuery,
} from "@tanstack/react-query";
import { z } from "zod";

import { type TypeFormInputs } from "@/components/project-list/settings/AddTypeForm";
import { fetchClient } from "@/definitions";
import financing, { type Financing } from "@/utils/schemas/financing";

type FinanceTypeOptions = {
	projectId?: number | string;
};

export function financeTypeQuery(options?: FinanceTypeOptions) {
	const { projectId } = options ?? {};

	return {
		queryKey: ["finances", projectId],
		queryFn: async () => {
			const res = await fetchClient(`Finance${projectId ? `?projectId=${projectId}` : ""}`);

			return z.array(financing).parse(res);
		},
	} satisfies UseQueryOptions;
}

export function createFinanceTypeQuery() {
	return {
		mutationKey: ["finances", "create"],
		mutationFn: async (data) => {
			const res = await fetchClient("Finance", {
				method: "POST",
				body: JSON.stringify(data),
			});

			return financing.parse(res);
		},
	} satisfies UseMutationOptions<Financing, unknown, TypeFormInputs>;
}

export function toggleFinanceTypeQuery() {
	return {
		mutationKey: ["finances", "toggle"],
		mutationFn: async (id: number) =>
			fetchClient(`Finance/ChangeStatus/${id}`, {
				method: "PATCH",
			}),
	} satisfies UseMutationOptions<void, unknown, number>;
}

export function deleteFinanceTypeQuery() {
	return {
		mutationKey: ["finances", "delete"],
		mutationFn: (id: number) =>
			fetchClient(`Finance/${id}`, {
				method: "DELETE",
			}),
	} satisfies UseMutationOptions<void, unknown, number>;
}

export default function useFinanceType(options?: FinanceTypeOptions) {
	return useQuery(financeTypeQuery(options));
}
