import { ReactNode, createContext, useEffect, useMemo, useState } from "react";

import { USER_ROLES } from "@/constants/enums";
import { storageKeys } from "@/definitions";
import { LoggedInUser, User } from "@/utils/schemas";

type UserContext = {
	authToken: string;
	updateToken: (token: string) => void;
	currentUser: User | null;
	updateUser: (user: User | null) => void;

	onLogIn: (data: LoggedInUser) => void;
	onLogOut: () => void;
	isAdmin: boolean;
	isManager: boolean;
	isInternalIntervenant: boolean;
};

export const UserContext = createContext<UserContext>({} as UserContext);

type Props = {
	children: ReactNode;
};

export function UserProvider({ children }: Props) {
	const [authToken, setAuthToken] = useState(
		localStorage.getItem(storageKeys.authToken) ?? "",
	);
	const [currentUser, setCurrentUser] = useState<User | null>(null);

	useEffect(() => {
		onRender();
	}, []);

	const onRender = () => {
		const token = localStorage.getItem(storageKeys.authToken);
		setAuthToken(token ?? "");

		const user = localStorage.getItem(storageKeys.user) ?? "";
		user ? setCurrentUser(JSON.parse(user) as User) : setCurrentUser(null);
	};

	const updateToken = (token: string) => {
		setAuthToken(token);
		localStorage.setItem(storageKeys.authToken, token);
	};

	const updateUser = (user: User | null) => {
		setCurrentUser(user);
		localStorage.setItem(storageKeys.user, JSON.stringify(user));
	};

	const onLogIn = (data: LoggedInUser) => {
		updateToken(data.bearerToken);
		updateUser(data.user);
	};

	const onLogOut = () => {
		updateToken("");
		updateUser(null);
	};

	const roleBools = useMemo(() => {
		return {
			isAdmin: currentUser?.role?.value === USER_ROLES.SUPER_ADMIN,
			isManager: currentUser?.role?.value === USER_ROLES.MANAGER,
			isInternalIntervenant:
				currentUser?.role?.value === USER_ROLES.INTERNAL_INTERVENER,
		};
	}, [currentUser, currentUser?.role]);

	return (
		<UserContext.Provider
			value={{
				authToken,
				updateToken,
				currentUser,
				updateUser,
				onLogIn,
				onLogOut,
				...roleBools,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}
