import { Typography } from "antd";
import { PropsWithChildren, type ReactNode } from "react";

import cn from "@/utils/cn";

export type LabelProps = {
	icon?: ReactNode;
	className?: string;
	containerClassName?: string;
	required?: boolean;
};

const { Paragraph } = Typography;

//dep

export default function Label({
	icon,
	className,
	containerClassName,
	children,
	required
}: PropsWithChildren<LabelProps>) {
	return (
		<Typography className={cn("flex", containerClassName)}>
			{icon}

			<Paragraph className={cn("m-2 font-bold", className)}>
				{children}
				{required && <span className={"text-red-400 ml-1"}>*</span>}
			</Paragraph>
		</Typography>
	);
}
