import { z } from "zod";

export type EstablishmentType = "internal" | "external";

export const baseEstablishmentForm = z.object({
	name: z.string(),
	code: z.string().nullish(),
	companyName: z.string().nullish(),
	socialReason: z.string().nullish(),
	function: z.string().nullish(),
	establishmentLegalManager: z.string().nullish(),
	address: z.string().nullish(),
	city: z.string().nullish(),
	deputyDirector: z.string().nullish(),
	email: z.string().nullish(),
	telephone: z.string().nullish(),
	active: z.boolean().nullish(),
});

export const externalEstablishmentForm = baseEstablishmentForm;

export const establishmentForm = baseEstablishmentForm.extend({
	internalCode: z.string().nullish(),
	siret: z.string().nullish(),
	leagalFINESS: z.string().nullish(),
	geoFINESS: z.string().nullish(),
});

export const externalEstablishment = externalEstablishmentForm.extend({
	id: z.number(),
	/*
	 * HACK: There is a problem on the backend where created date can sometimes be "0001-01-01"
	 * which is not valid DateTime format, that's why this only checks if it's a `string`
	 */
	created: z.string().nullish(),
	updated: z.string().nullish(),
	type: z.string().nullable(),
});

const establishment = establishmentForm.extend({
	id: z.number(),
	/*
	 * HACK: There is a problem on the backend where created date can sometimes be "0001-01-01"
	 * which is not valid DateTime format, that's why this only checks if it's a `string`
	 */
	created: z.string().nullish(),
	updated: z.string().nullish(),
	type: z.string().nullable(),
});
export default establishment;

export type Establishment = z.infer<typeof establishment>;
export type ExternalEstablishment = z.infer<typeof externalEstablishment>;
export type EstablishmentForm = Partial<Establishment>;
export type ExternalEstablishmentForm = Partial<ExternalEstablishment>;
