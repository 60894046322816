import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Form, Input } from "antd";
import { useMemo } from "react";
import {
	Controller,
	SubmitErrorHandler,
	SubmitHandler,
	useForm,
} from "react-hook-form";
import { z } from "zod";

import { queryClient } from "@/App";
import Label from "@/components/Label";
import StateToggle from "@/components/StateToggle";
import { useToast } from "@/context";
import { createFinanceTypeQuery } from "@/hooks/query/useFinanceType";
import { createProjectTypeQuery } from "@/hooks/query/useProjectType";
import { useI18nContext } from "@/i18n/i18n-react";
import {createStageQuery} from "@/hooks/query/useProjectStage";

const typeSchema = z.object({
	name: z.string().min(1),
	active: z.boolean(),
});
export type TypeFormInputs = z.infer<typeof typeSchema>;

const defaultValues: TypeFormInputs = {
	name: "",
	active: true,
};

export type AddTypeFormProps = {
	variant: "project" | "financement" | "stage";
	onCreate: () => void;
};

export default function AddTypeForm({ variant, onCreate }: AddTypeFormProps) {
	const {
		LL: {
			ProjectModule: {
				projectList: { settings: LL },
			},
			toasts: ToastLL,
		},
	} = useI18nContext();

	const { toast } = useToast();

	const { control, handleSubmit, reset } = useForm<TypeFormInputs>({
		defaultValues,
		resolver: zodResolver(typeSchema),
	});

	const mutationOptions = useMemo(() => {
		switch (variant) {
			case "project":
				return createProjectTypeQuery();
			case "financement":
				return createFinanceTypeQuery();
			case "stage":
				return createStageQuery();
		}
	}, [variant]);

	const { mutate, isLoading } = useMutation({
		...mutationOptions,
		onSuccess: async () => {
			reset();
			onCreate();
			await queryClient.invalidateQueries();
			toast.success({
				message: ToastLL.success(),
				description: ToastLL.createdSuccessfully(),
			});
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	const onValid: SubmitHandler<TypeFormInputs> = (data) => {
		mutate(data);
	};

	const onInvalid: SubmitErrorHandler<TypeFormInputs> = (errors) => {
		console.error(errors);
	};

	return (
		<form onSubmit={handleSubmit(onValid, onInvalid)}>
			<Card
				bordered={false}
				className="rounded-none bg-[#fafafa]"
				bodyStyle={{
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					paddingTop: 0,
					paddingBottom: 0,
				}}
			>
				<Label className="m-0 text-xl">
					{LL.forms[`${variant}Type`].label()}
				</Label>

				<label>
					<Label className="m-0 mb-2">
						{LL.forms[`${variant}Type`].nameOfTheType()}
					</Label>

					<Controller
						name="name"
						control={control}
						render={({ field, fieldState }) => (
							<Form.Item
								validateStatus={fieldState.invalid ? "error" : "success"}
								help={fieldState.error?.message ?? ""}
							>
								<Input {...field} />
							</Form.Item>
						)}
					/>
				</label>

				<div className="flex flex-row justify-between">
					<Controller
						name="active"
						control={control}
						render={({ field: { value, onChange } }) => (
							<StateToggle checked={value} onChange={onChange} />
						)}
					/>

					<Button
						className="self-end border-none bg-black text-white"
						htmlType="submit"
						loading={isLoading}
					>
						{LL.addOptionButton()}
					</Button>
				</div>
			</Card>
		</form>
	);
}
