import { z } from "zod";

import { fetchClient } from "@/definitions";
import { UseRequestParamsReturn } from "@/hooks";
import { defaultPatchOptions } from "@/hooks/query/useProjectData";
import convertToUrlParams from "@/utils/convert-to-url-params";
import { responseSchema } from "@/utils/schemas";
import projectSchema from "@/utils/schemas/project";

export const getProjects = async (
	params?: Pick<UseRequestParamsReturn, "orderBy" | "pagination" | "reqParams">,
) => {
	const queryParams = convertToUrlParams(params);
	const res = await fetchClient(
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		`Projects?${queryParams}`,
	);

	return responseSchema.extend({ projects: z.array(projectSchema) }).parse(res);
};

export const getProjectsPdf = async (
	params?: Pick<UseRequestParamsReturn, "orderBy" | "reqParams">,
) => {
	const queryParams = convertToUrlParams(params);
	await fetchClient(
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		`Projects/PDF?${queryParams}`,
	);

	// if (res.ok) {
	// 	const data = res
	// 	return responseSchema
	// 		.extend({ projects: z.array(projectSchema) })
	// 		.parse(data);
	// }

	return null;
};

export const getProject = async (id?: string) => {
	if (!id) return null;

	const res = await fetchClient(`Projects/${id}`);

	return projectSchema.parse(res);
};

export const updateProjectUserRole = async (data: {
	projectId: string;
	userId: number;
	role: number;
}) => {
	const { projectId, userId, role } = data;
	const res = await fetchClient(`Projects/${projectId}/UserRole/${userId}`, {
		body: JSON.stringify({ role }),
		...defaultPatchOptions,
	});

	return projectSchema.parse(res);
};

export const updateProjectExternalUsers = async (data: {
	projectId: string;
	userId: number;
}) => {
	const { projectId, userId } = data;
	const res = await fetchClient(
		`Projects/${projectId}/ExternalIntervenant/${userId}`,
		{
			body: JSON.stringify({}),
			...defaultPatchOptions,
		},
	);

	return projectSchema.parse(res);
};
export const updateProjectFileReferent = async (data: {
	projectId: string;
	userId: number;
}) => {
	const { projectId, userId } = data;
	const res = await fetchClient(
		`Projects/${projectId}/FileReferent/${userId}`,
		{
			body: JSON.stringify({}),
			...defaultPatchOptions,
		},
	);

	return projectSchema.parse(res);
};
