import {
	type UseMutationOptions,
	type UseQueryOptions,
	useQuery,
} from "@tanstack/react-query";

import { getProject } from "@/api";
import { CreateProjectForm } from "@/components/project-list/CreateProjectModal";
import { fetchClient } from "@/definitions";
import { type ProjectForm } from "@/pages/project-list/project-form";
import projectSchema, { type Project } from "@/utils/schemas/project";

export const defaultPatchOptions = {
	method: "PATCH",
	headers: { "Content-Type": "application/json" },
} as const;

const defaultDeleteOptions = {
	method: "DELETE",
	headers: { "Content-Type": "application/json" },
};

export function projectQuery(id?: string) {
	return {
		queryKey: ["project", id],
		queryFn: () => getProject(id),
		enabled: !!id,
	} satisfies UseQueryOptions;
}

export default function useProjectData(id?: string) {
	return useQuery(projectQuery(id));
}

export function createProjectQuery(): UseMutationOptions<
	Project | null,
	unknown,
	CreateProjectForm
> {
	return {
		mutationKey: ["project"],
		mutationFn: async (data) => {
			const res = await fetchClient(`Projects`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			});

			return projectSchema.parse(res);
		},
		retry: 0,
	};
}

export function deleteProjectQuery(id: string): UseMutationOptions {
	return {
		mutationKey: ["project", "delete", id],
		mutationFn: async () => {
			const res = await fetchClient(`Projects/${id}`, {
				method: "DELETE",
			});

			return res;
		},
	};
}

export type PatchProjectData = Omit<
	Partial<ProjectForm>,
	"establishments" | "finances" | "projectTypes"
> & {
	establishments?: {
		added: ProjectForm["establishments"];
		deleted: ProjectForm["establishments"];
	};
	finances?: {
		added: ProjectForm["finances"];
		deleted: ProjectForm["finances"];
	};
	projectTypes?: {
		added: ProjectForm["projectTypes"];
		deleted: ProjectForm["projectTypes"];
	};
};

export function patchProjectQuery(
	id: string,
): UseMutationOptions<Project | null, unknown, PatchProjectData> {
	return {
		mutationKey: ["project", id],
		mutationFn: async (project) => {
			const res = await fetchClient(`Projects/${id}`, {
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(project),
			});

			const newProjectData = projectSchema.parse(res);

			let patchEstablishmentsResponse: null | Promise<unknown> = null;
			let deleteEstablishmentsResponse: null | Promise<unknown> = null;
			if (project.establishments) {
				const { added, deleted } = project.establishments;

				if (added?.length)
					patchEstablishmentsResponse = fetchClient(
						`Projects/${id}/Establishments?${added
							.map((id) => `ids=${id}`)
							.join("&")}`,
						{
							...defaultPatchOptions,
						},
					);

				if (deleted?.length)
					deleteEstablishmentsResponse = fetchClient(
						`Projects/${id}/Establishments?${deleted
							.map((id) => `ids=${id}`)
							.join("&")}`,
						{
							...defaultDeleteOptions,
						},
					);
			}

			if (project.stage) {
				await fetchClient(
					`Projects/${id}/Stages/${project.stage}`,
					{
						...defaultPatchOptions,
					},
				);
			}

			let patchFinancesResponse: null | Promise<unknown> = null;
			let deleteFinancesResponse: null | Promise<unknown> = null;
			if (project.finances) {
				const { added, deleted } = project.finances;

				if (added?.length)
					patchFinancesResponse = fetchClient(
						`Projects/${id}/Finances?${added
							.map((id) => `ids=${id}`)
							.join("&")}`,
						{
							...defaultPatchOptions,
						},
					);

				if (deleted?.length)
					deleteFinancesResponse = fetchClient(
						`Projects/${id}/Finances?${deleted
							.map((id) => `ids=${id}`)
							.join("&")}`,
						{
							...defaultDeleteOptions,
						},
					);
			}

			let patchProjectTypesResponse: null | Promise<unknown> = null;
			let deleteProjectTypesResponse: null | Promise<unknown> = null;
			if (project.projectTypes) {
				const { added, deleted } = project.projectTypes;

				if (added?.length)
					patchProjectTypesResponse = fetchClient(
						`Projects/${id}/ProjectTypes?${added
							.map((id) => `ids=${id}`)
							.join("&")}`,
						{
							...defaultPatchOptions,
						},
					);

				if (deleted?.length)
					deleteProjectTypesResponse = fetchClient(
						`Projects/${id}/ProjectTypes?${deleted
							.map((id) => `ids=${id}`)
							.join("&")}`,
						{
							...defaultDeleteOptions,
						},
					);
			}

			await Promise.all([
				patchEstablishmentsResponse,
				deleteEstablishmentsResponse,
				patchFinancesResponse,
				deleteFinancesResponse,
				patchProjectTypesResponse,
				deleteProjectTypesResponse,
			]);

			return newProjectData;
		},
	};
}

export function patchProjectUsersRoleQuery(
	id: string,
): UseMutationOptions<Project | null, unknown, [number, number]> {
	return {
		mutationKey: ["project", "UsersRole", id],
		mutationFn: async ([userId, role]: [number, number]) => {
			const res = await fetchClient(`Projects/${id}/UsersRole/${userId}`, {
				body: JSON.stringify({ role }),
				...defaultPatchOptions,
			});

			return projectSchema.parse(res);
		},
	};
}
