import { FileTextOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";

import { deleteFile, updateFileType, uploadFile } from "@/api";
import DocumentsList, {
	DocumentsListProps,
} from "@/components/project-list/modify-project/documents-form/DocumentsList";
import { useToast } from "@/context";
import { useStrictDroppable } from "@/hooks";
import { useI18nContext } from "@/i18n/i18n-react";
import { type ProjectFile } from "@/utils/schemas";

export type DocumentsForm = {
	files: ProjectFile[];
	isLoading?: boolean;
	canEdit: boolean;
};

export const DocumentsForm = function ({
	files,
	isLoading,
	canEdit,
}: DocumentsForm) {
	const {
		LL: {
			ProjectModule: { projectForm: LL },
			toasts: ToastLL,
		},
	} = useI18nContext();
	const queryClient = useQueryClient();

	const { id } = useParams();

	const [sections, setSections] = useState<DocumentsListProps<number>[]>([]);
	const [isUploading, setIsUploading] = useState(false);

	const { toast } = useToast();

	// issue related to double useEffect of React, see https://github.com/atlassian/react-beautiful-dnd/issues/2396#issuecomment-1248018320
	const [filesShown] = useStrictDroppable(!!isLoading);

	const onFileAdd = async (file: File, type: number) => {
		if (id) {
			try {
				setIsUploading(true);
				await uploadFile(file, {
					entityId: id,
					entity: "Project",
					fileType: type,
				});
				setIsUploading(false);
				toast.success({
					message: ToastLL.success(),
					description: ToastLL.documentCreatedSuccessfully(),
				});
				return queryClient.invalidateQueries();
			} catch (e: any) {
				toast.error({
					message: ToastLL.error(),
					description: e.message ?? ToastLL.somethingIsNoYes(),
				});
			}
		}
	};

	const onFileTypeUpdate = async (fileId: number, filetType: number) => {
		if (id) {
			await updateFileType(fileId, filetType);
			await queryClient.invalidateQueries();
		}
	};

	const onFileRemove = async (fileId: number) => {
		if (id) {
			await deleteFile(fileId);
			await queryClient
				.invalidateQueries()
				.then(() => {
					toast.success({
						message: ToastLL.success(),
						description: ToastLL.documentDeletedSuccessfully(),
					});
				})
				.catch((e) => {
					console.error(e);
					toast.error({
						message: ToastLL.error(),
						description: e.message ?? ToastLL.somethingIsNoYes(),
					});
				});
		}
	};

	const sectionBase = (type: number) => ({
		addButton: { text: LL.addOrDropOptionButton() },
		icon: <FileTextOutlined />,
		onUpload: onFileAdd,
		onRemove: onFileRemove,
		files: files.filter((file) => file.fileType === type),
		isUploading,
		canEdit,
	});

	useEffect(() => {
		setSections([
			{
				...sectionBase(0),
				title: LL.documents.regulations(),
				index: 0,
				type: 0,
			},
			{
				...sectionBase(1),
				title: LL.documents.workAreaDraft(),
				index: 1,
				type: 1,
			},
			{
				...sectionBase(2),
				title: LL.documents.submission(),
				index: 2,
				type: 2,
			},
			{
				...sectionBase(3),
				title: LL.documents.financement(),
				index: 3,
				type: 3,
			},
			{
				...sectionBase(4),
				title: LL.documents.followUp(),
				index: 4,
				type: 4,
			},
		]);
	}, [files, id]);

	async function onDragEnd(result: DropResult) {
		const { source, destination, draggableId } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}
		const sInd = +source.droppableId;
		const dInd = +destination.droppableId;

		if (sInd === dInd) {
			return;
		}

		const oldSectionIndex = sections.findIndex(
			(section) => section.type.toString() === source.droppableId,
		);

		const newSectionIndex = sections.findIndex(
			(section) => section.type.toString() === destination.droppableId,
		);

		const reorderedSections = [...sections];

		const fileIndex = reorderedSections[oldSectionIndex].files.findIndex(
			(file) => file.id.toString() === draggableId,
		);

		const reorderedFile = {
			...reorderedSections[oldSectionIndex].files[fileIndex],
		};

		reorderedSections[oldSectionIndex].files.splice(fileIndex, 1);

		reorderedSections[newSectionIndex] = {
			...reorderedSections[newSectionIndex],
			files: [...reorderedSections[newSectionIndex].files, reorderedFile],
		};

		setSections(reorderedSections);

		await onFileTypeUpdate(
			+draggableId,
			reorderedSections[newSectionIndex].type,
		);
	}

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<div className="grid h-full grid-rows-3 gap-4">
					{[sections[0], sections[2], sections[4]].map(
						(section) =>
							filesShown && (
								<DocumentsList
									key={section.type}
									{...section}
									isUploading={isUploading}
								/>
							),
					)}
				</div>

				<div className="grid h-full grid-rows-2 gap-4">
					{[sections[1], sections[3]].map(
						(section) =>
							filesShown && (
								<DocumentsList
									key={section.type}
									{...section}
									isUploading={isUploading}
								/>
							),
					)}
				</div>
			</DragDropContext>
		</>
	);
};
