import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { type QueryClient } from "@tanstack/react-query";
import {
	Button,
	ConfigProvider,
	Drawer,
	Layout,
	Menu,
	MenuProps,
	Typography,
} from "antd";
import { useContext } from "react";
import { type LoaderFunctionArgs, Outlet } from "react-router-dom";

import TypeSection from "@/components/project-list/settings/TypeSection";
import { ModuleSettingsContext, UserContext } from "@/context";
import useFinanceType, { financeTypeQuery } from "@/hooks/query/useFinanceType";
import useProjectStage from "@/hooks/query/useProjectStage";
import useProjectType, { projectTypeQuery } from "@/hooks/query/useProjectType";
import { useI18nContext } from "@/i18n/i18n-react";

export function loader(queryClient: QueryClient) {
	return async function (_args: LoaderFunctionArgs) {
		const projectTypes =
			queryClient.getQueryData(projectTypeQuery().queryKey) ??
			queryClient.fetchQuery(projectTypeQuery());

		const financeTypes =
			queryClient.getQueryData(financeTypeQuery().queryKey) ??
			queryClient.fetchQuery(financeTypeQuery());

		return Promise.all([projectTypes, financeTypes]);
	};
}

export default function ProjectsList() {
	const {
		LL: {
			ProjectModule: { projectList: LL },
		},
	} = useI18nContext();

	const { tab, dispatchTab } = useContext(ModuleSettingsContext);
	const { isAdmin } = useContext(UserContext);

	function handleOpenProjectTypesTab() {
		dispatchTab("project");
	}

	function handleOpenFinancesTab() {
		dispatchTab("financement");
	}

	function handleOpenStagesTab() {
		dispatchTab("stage");
	}

	function handleCloseDrawer() {
		dispatchTab(null);
	}

	const handleChangeTab: MenuProps["onClick"] = (e) => {
		switch (e.key) {
			case "project":
				handleOpenProjectTypesTab();
				break;
			case "financement":
				handleOpenFinancesTab();
				break;
			case "stage":
				handleOpenStagesTab();
				break;
		}
	};

	const { data: projectTypes, isFetching: isProjectTypesFetching } =
		useProjectType();

	const { data: financeTypes, isFetching: isFinancesFetching } =
		useFinanceType();

	const { data: stages, isFetching: isStagesFetching } = useProjectStage();

	return (
		<Layout.Content className="flex flex-col items-center gap-3 md:py-3 lg:px-4 xl:py-12">
			<Typography className="flex w-full sm:flex-row justify-between p-6 md:p-0 flex-col">
				<Typography.Title>{LL.title()}</Typography.Title>

				{isAdmin && (
					<Button
						type="link"
						className="flex text-[#343434] underline p-0"
						onClick={handleOpenProjectTypesTab}
					>
						{LL.settings.label()}

						<SettingOutlined className="text-lg" />
					</Button>
				)}
			</Typography>

			<Outlet />

			<ConfigProvider
				theme={{
					components: {
						Layout: {
							headerBg: "#e8e8f4",
							siderBg: "#e8e8f4",
						},
						Menu: {
							colorBgContainer: "#e8e8f4",
							colorSplit: "#e8e8f4",
							colorPrimary: "#343434",
						},
					},
				}}
			>
				<Drawer
					open={!!tab}
					contentWrapperStyle={{
						borderTopLeftRadius: "10px",
						overflow: "hidden",
					}}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					getContainer={document.getElementById("root")!}
					size="large"
					bodyStyle={{ padding: 0, display: "flex" }}
					closable={false}
					onClose={handleCloseDrawer}
				>
					<Layout className="bg-white">
						<Layout.Sider width={250} className="p-5 pr-3 pt-12">
							<Typography>
								<Typography.Title level={3} className="ml-5">
									{LL.settings.label()}
								</Typography.Title>
							</Typography>

							<Menu
								className="text-sm text-[#343434]"
								defaultSelectedKeys={["projectTypes"]}
								selectedKeys={[tab ?? "projectTypes"]}
								onClick={handleChangeTab}
								items={[
									{
										key: "project",
										label: LL.settings.projectType(),
										icon: <SettingOutlined />,
									},
									{
										key: "financement",
										label: LL.settings.financementType(),
										icon: <SettingOutlined />,
									},
									{
										key: "stage",
										label: LL.settings.stageType(),
										icon: <SettingOutlined />,
									},
								]}
							/>
						</Layout.Sider>

						<Layout.Content className="flex flex-col gap-5 p-5 overflow-y-auto">
							<CloseOutlined
								className="cursor-pointer self-end"
								onClick={handleCloseDrawer}
							/>

							{(() => {
								switch (tab) {
									case "project":
										return (
											<TypeSection
												variant="project"
												options={projectTypes ?? []}
												loading={isProjectTypesFetching}
											/>
										);
									case "financement":
										return (
											<TypeSection
												variant="financement"
												options={financeTypes ?? []}
												loading={isFinancesFetching}
											/>
										);
									case "stage":
										return (
											<TypeSection
												variant="stage"
												options={stages ?? []}
												loading={isStagesFetching}
											/>
										);
								}
							})()}
						</Layout.Content>
					</Layout>
				</Drawer>
			</ConfigProvider>
		</Layout.Content>
	);
}
