import {
	MEDIA_QUERIES,
	type MediaQueriesValues,
} from "@/constants/mediaQueries";

export default function getMediaQuery(): MediaQueriesValues {
	let mediaSize: MediaQueriesValues = MEDIA_QUERIES[0];

	for (const [key, value] of Object.entries(MEDIA_QUERIES)) {
		const e = window.matchMedia(`(min-height: ${key}px)`);

		if (e.matches) mediaSize = value;
	}

	return mediaSize;
}
