import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider, theme } from "antd";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import {
	Navigate,
	RouterProvider,
	createBrowserRouter,
} from "react-router-dom";

import {
	DrawerProvider,
	ModuleSettingsDrawerProvider,
	ToastProvider,
	UserContext,
	UserProvider,
} from "@/context";
import TypesafeI18n from "@/i18n/i18n-react";
import { loadLocaleAsync } from "@/i18n/i18n-util.async";

import NavBar, { loader as navBarLoader } from "./layouts/navbar";
import ErrorPage from "./pages/error";
import Home from "./pages/home";
import Login from "./pages/login";
import ProjectsList from "./pages/project-list";
import ProjectForm, {
	loader as projectFormLoader,
} from "./pages/project-list/project-form";
import ProjectsTable from "./pages/project-list/projects-table";

export const queryClient = new QueryClient();
const { useToken } = theme;

const ProtectedRoute = ({ children }: PropsWithChildren<any>) => {
	const { authToken } = useContext(UserContext);

	if (!authToken) {
		return <Navigate to="/login" />;
	}

	return <>{children}</>;
};

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<ProtectedRoute>
				<DrawerProvider>
					<NavBar />
				</DrawerProvider>
			</ProtectedRoute>
		),
		loader: navBarLoader(queryClient),
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "projects",
				element: (
					<ModuleSettingsDrawerProvider>
						<ProjectsList />
					</ModuleSettingsDrawerProvider>
				),
				children: [
					{
						index: true,
						element: <ProjectsTable />,
					},
					{
						path: ":id",
						element: <ProjectForm />,
					},
				],
			},
		],
	},
	{
		path: "/login",
		element: <Login />,
		errorElement: <ErrorPage />,
	},
]);

export default function App() {
	const locale = "pl";

	// This loads locales
	const [localesLoaded, setLocalesLoaded] = useState(false);
	const { token } = useToken();

	useEffect(() => {
		void loadLocaleAsync(locale).then(() => {
			setLocalesLoaded(true);
		});
	}, [locale]);

	if (!localesLoaded) {
		return null;
	}

	return (
		<TypesafeI18n locale={locale}>
			<QueryClientProvider client={queryClient}>
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: "#005373",
						},
						components: {
							Input: {
								borderRadius: 2,
								colorBgContainerDisabled: token.colorBgContainer,
								colorTextDisabled: token.colorText,
							},
							Select: {
								borderRadius: 2,
								borderRadiusLG: 2,
								colorBgContainerDisabled: token.colorBgContainer,
								colorTextDisabled: token.colorText,
							},
							Checkbox: {
								colorTextDisabled: token.colorText,
								colorBgContainerDisabled: token.colorBgContainer,
							},
							DatePicker: {
								borderRadius: 2,
							},
							Button: {
								borderRadius: 2,
							},
							Card: {
								boxShadow: "",
								boxShadowSecondary: "",
								boxShadowTertiary: "",
							},
						},
					}}
				>
					<ToastProvider>
						<UserProvider>
							<RouterProvider router={router} />
						</UserProvider>
					</ToastProvider>
				</ConfigProvider>

				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</TypesafeI18n>
	);
}
