import { useContext } from "react";
import {
	Navigate,
	isRouteErrorResponse,
	useRouteError,
} from "react-router-dom";
import { ZodError } from "zod";

import { UserContext } from "@/context";

export default function ErrorPage() {
	const error = useRouteError();
	const { authToken } = useContext(UserContext);

	if (!authToken) {
		return <Navigate to="/login" />;
	}

	return (
		<div className="min-h-screen flex flex-col justify-center items-center">
			<h1 className="text-6xl font-bold">Something went wrong :(</h1>
			<p>
				{(() => {
					if (error instanceof ZodError)
						return "Something went wrong when calling the API";

					if (isRouteErrorResponse(error))
						switch (error.status) {
							case 404:
								return "This page doesn't exist!";
							case 401:
							case 403:
								return "You are unauthorized to see this";
							case 503:
								return "Looks like the API is down";
							default:
								return "And we didn't forsee this!";
						}
				})()}
			</p>
		</div>
	);
}
