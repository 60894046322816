import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, Form, Input, Typography } from "antd";
import { Controller, type FieldErrors, useForm } from "react-hook-form";
import { z } from "zod";

import { useI18nContext } from "@/i18n/i18n-react";

export const loginSchema = z.object({
	login: z.string(),
	// email: z.string().email(),
	password: z.string().min(1),
});

export type LoginFormInputs = z.infer<typeof loginSchema>;

type LoginForm = {
	onSubmit: (data: LoginFormInputs) => void;
};

export default function LoginForm({ onSubmit }: LoginForm) {
	const {
		LL: {
			Login: { LoginForm: LL },
		},
	} = useI18nContext();

	const { control, handleSubmit } = useForm<LoginFormInputs>({
		resolver: zodResolver(loginSchema),
		defaultValues: {
			login: "",
			password: "",
		},
	});

	function onValid(data: LoginFormInputs) {
		onSubmit(data);
	}

	function onInvalid(errors: FieldErrors<LoginFormInputs>) {
		console.error(errors);
	}

	return (
		<Card bordered={false} className="shadow-md">
			<Typography>
				<Typography.Paragraph className="mb-5 text-3xl font-thin">
					{LL.connection()}
				</Typography.Paragraph>
			</Typography>

			<form
				onSubmit={handleSubmit(onValid, onInvalid)}
				className="mb-5 flex flex-col bg-white"
			>
				<Controller
					name="login"
					control={control}
					render={({ field, fieldState }) => (
						<Form.Item
							validateStatus={fieldState.invalid ? "error" : "success"}
							help={fieldState.error?.message ?? ""}
						>
							<Input
								placeholder={LL.email()}
								className="p-3"
								type="text"
								{...field}
							/>
						</Form.Item>
					)}
				/>

				<Controller
					name="password"
					control={control}
					render={({ field, fieldState }) => (
						<Form.Item
							validateStatus={fieldState.invalid ? "error" : "success"}
							help={fieldState.error?.message ?? ""}
						>
							<Input.Password
								placeholder={LL.password()}
								className="p-3"
								{...field}
							/>
						</Form.Item>
					)}
				/>

				<Button htmlType="submit" type="primary" size="large">
					{LL.login()}
				</Button>
			</form>

			<Typography>
				{LL.dontHaveAccount()} <a href="#">{LL.createOne()}</a>
			</Typography>
		</Card>
	);
}
