import { z } from "zod";

import alert from "./alert";
import establishment from "./establishment";
import financingType from "./financing";
import user from "./user";

export const projectType = z.object({
	id: z.number(),
	name: z.string(),
	created: z.string(),
	updated: z.string(),
	active: z.boolean().nullable(),
});
export type ProjectType = z.infer<typeof projectType>;

const projectUsers = z.array(z.object({ user: user, role: z.number() }));

export const stageSchema = z.object({
	id: z.number(),
	created: z.string(),
	updated: z.string(),
	name: z.string(),
	active: z.boolean()
});

export type Stage = z.infer<typeof stageSchema>;

export const projectStage = z.object({
	value: z.number(),
	label: z.string(),
});
export type ProjectStages = z.infer<typeof projectStage>;

export const projectFileToUpload = z.object({
	name: z.string(),
	size: z.number(),
	fileType: z.string(),
	file: z.instanceof(File),
});
export type ProjectFileToUpload = z.infer<typeof projectFileToUpload>;

export const projectFile = z.object({
	name: z.string(),
	size: z.number(),
	fileType: z.number(),
	id: z.number(),
	created: z.string(),
	updated: z.string(),
	description: z.string().nullish(),
	path: z.string(),
});

export type ProjectFile = z.infer<typeof projectFile>;

const projectSchema = z.object({
	id: z.number(),
	accessLevel: z.union([z.literal(0), z.literal(1)]).nullish(),
	establishments: z.array(establishment).nullable(),
	projectTypes: z.array(projectType).nullish(),
	finances: z.array(financingType).nullable(),
	alerts: z.array(alert).nullable(),
	nextAlert: alert.nullable(),

	// TODO: Update the shape to be representable of current state of backend
	users: z.array(user).nullable(),
	usersRoles: projectUsers.nullable(),
	externalIntervenant: user.nullable(),
	fileReferent: user.nullable(),

	created: z.string(),
	updated: z.string(),
	dueDate: z.string().nullable(),
	name: z.string(),
	description: z.string().nullable(),
	stage: projectStage.nullable(),
	active: z.boolean(),

	files: z.array(projectFile),
});

export default projectSchema;

export type Project = z.infer<typeof projectSchema>;

export const projectListRequestSchema = z.object({
	name: z.string().optional(),
	description: z.string().optional(),
	establishmentIds: z.array(z.number()).optional(),
	statuses: z.array(z.number()).optional(),
	stageIds: z.array(z.number()).optional(),
	alertIds: z.array(z.number()).optional(),
	contextUserId: z.number().optional(),
	createdDateTimes: z.string().optional(),
	// FIXME: create a component to handle range
	// createdDateTimes: z.object({
	// 	start: z.string().datetime(),
	// 	end: z.string().datetime(),
	// }),
	// finishedDateTimes: z.object({
	// 	start: z.string().datetime(),
	// 	end: z.string().datetime(),
	// }),
	finishedDateTimes: z.string().optional(),
});

export type ProjectListRequest = z.infer<typeof projectListRequestSchema>;
