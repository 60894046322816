import { Button, type ButtonProps } from "antd";
import { type LocalizedString } from "typesafe-i18n";

import CheckboxGroup, {
	type MyCheckboxGroupProps,
} from "@/components/CheckboxGroup";
import Label, { type LabelProps } from "@/components/Label";
import cn from "@/utils/cn";

type CheckboxModuleProps = {
	label: string | LocalizedString;
	labelProps: LabelProps;
} & (
	| {
			isExtendable?: boolean;
			addButton?: {
				text?: LocalizedString;
			} & Omit<ButtonProps, "type">;
	  }
	| { isExtendable?: false; addButton: never }
) &
	Omit<MyCheckboxGroupProps, "header">;

export function CheckboxGroupModule({
	label,
	className,
	labelProps: { icon, className: labelClassName, containerClassName },
	isExtendable = false,
	addButton,
	...props
}: CheckboxModuleProps) {
	return (
		<CheckboxGroup
			{...props}
			className="min-h-full"
			header={
				<div
					className={cn(
						"@3xs:flex-row flex flex-col justify-between",
						className,
					)}
				>
					<Label
						icon={icon}
						className={labelClassName}
						containerClassName={containerClassName}
					>
						{label}
					</Label>

					{isExtendable ? (
						<Button
							type="link"
							className={cn(
								"@3xs:self-auto self-start text-xs text-[#a2a2a2] p-0",
								addButton?.className,
							)}
							{...addButton}
						>
							<span className="mr-1 underline">{addButton?.text}</span> +
						</Button>
					) : null}
				</div>
			}
		/>
	);
}
