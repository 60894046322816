import { UseRequestParamsReturn } from "@/hooks";
import capitalizeString from "@/utils/capitalizeString";
import { ListRequest } from "@/utils/schemas";

export const convertToSearchParams = (
	filters?: Record<string, string | string[] | number | number[]>,
) => {
	const params = new URLSearchParams();
	if (filters) {
		Object.keys(filters).forEach((key) => {
			if (typeof filters[key] === "object") {
				const param = filters[key] as string[];
				param.forEach((val) => {
					params.append(capitalizeString(key), val.toString());
				});
			}
			if (typeof filters[key] === "string" && !!filters[key]) {
				(filters[key] as string).split(",").forEach((item: string) => {
					params.append(capitalizeString(key), item);
				});
			}
		});
	}
	return params;
};

export const convertPagingParams = (
	data?: UseRequestParamsReturn["pagination"],
) => {
	const params = new URLSearchParams();
	const { current = 1, pageSize = 10 } = data ?? {};
	params.append("Take", pageSize.toString());
	params.append("Skip", ((current - 1) * pageSize).toString());
	return params;
};

export const convertSortingParams = (data?: ListRequest["orderBy"]) => {
	const params = new URLSearchParams();
	const { parameter, descending } = data ?? {};
	if (!parameter) return params;
	params.append("OrderBy.Parameter", parameter.toString());
	params.append("OrderBy.Descending", descending?.toString() ?? "");
	return params;
};

const convertToUrlParams = (
	data?: Pick<UseRequestParamsReturn, "orderBy" | "reqParams"> & {
		pagination?: UseRequestParamsReturn["pagination"];
	},
) => {
	const { pagination, orderBy, reqParams } = data ?? {};
	const searchParams = convertToSearchParams(reqParams);
	const pagingParams = pagination && convertPagingParams(pagination);
	const sortingParams = convertSortingParams(orderBy);
	const queryParams = new URLSearchParams();

	for (const [key, val] of searchParams.entries()) {
		queryParams.append(key, val);
	}
	if (pagingParams) {
		for (const [key, val] of pagingParams.entries()) {
			queryParams.append(key, val);
		}
	}

	for (const [key, val] of sortingParams.entries()) {
		queryParams.append(key, val);
	}
	return queryParams;
};

export default convertToUrlParams;
