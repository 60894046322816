import { DeleteFilled } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Spin, Switch } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";

import { queryClient } from "@/App";
import Label from "@/components/Label";
import {
	ModuleSettingsContext,
	ModuleSettingsDrawerProvider,
	useToast,
} from "@/context";
import {
	createFinanceTypeQuery,
	deleteFinanceTypeQuery,
	toggleFinanceTypeQuery,
} from "@/hooks/query/useFinanceType";
import {
	createStageQuery,
	deleteStageQuery,
	toggleStageQuery,
} from "@/hooks/query/useProjectStage";
import {
	createProjectTypeQuery,
	deleteProjectTypeQuery,
	toggleProjectTypeQuery,
} from "@/hooks/query/useProjectType";
import { useI18nContext } from "@/i18n/i18n-react";
import { Financing, ProjectType, Stage } from "@/utils/schemas";

import AddTypeForm from "./AddTypeForm";

export type TypeSectionProps = {
	loading?: boolean;
} & (
	| {
			variant: "project";
			options: ProjectType[];
	  }
	| {
			variant: "financement";
			options: Financing[];
	  }
	| {
			variant: "stage";
			options: Stage[];
	  }
);

export default function TypeSection({
	variant,
	loading = false,
	options,
}: TypeSectionProps) {
	const {
		LL: {
			ProjectModule: {
				projectList: { settings: LL },
			},
			toasts: ToastLL,
		},
	} = useI18nContext();
	const { toast } = useToast();

	const [showForm, setShowForm] = useState(false);

	function toggleForm() {
		setShowForm((prev) => !prev);
	}

	const { tab } = useContext(ModuleSettingsContext);

	useEffect(() => {
		setShowForm(false);
	}, [tab]);

	const [deleteMutationOptions, toggleMutationOptions] = useMemo(() => {
		switch (variant) {
			case "project":
				return [deleteProjectTypeQuery(), toggleProjectTypeQuery()];
			case "financement":
				return [deleteFinanceTypeQuery(), toggleFinanceTypeQuery()];
			case "stage":
				return [deleteStageQuery(), toggleStageQuery()];
		}
	}, [variant]);

	const queryKey = useMemo(() => {
		switch (variant) {
			case "project":
				return ["projectTypes"];
			case "financement":
				return ["finances"];
			case "stage":
				return ["projectStages"];
		}
	}, [variant]);

	const { mutate: deleteType, isLoading: isLoadingDelete } = useMutation({
		...deleteMutationOptions,
		onSuccess: () => {
			void queryClient.refetchQueries({
				queryKey,
			});
			toast.success({
				message: ToastLL.success(),
				description: ToastLL.deletedSuccessfully(),
			});
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	const { mutate: toggleActive } = useMutation({
		...toggleMutationOptions,
		onSuccess: async () => {
			await queryClient.invalidateQueries();
			toast.success({
				message: ToastLL.success(),
				description: ToastLL.updatedSuccessfully(),
			});
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	return (
		<>
			<div className="flex flex-col max-h-[300px] min-h-[300px]">
				<div className="mb-2 flex justify-between">
					<Label className="mb-0 text-2xl">{LL[`${variant}Type`]()}</Label>

					<Button
						type="link"
						className="mb-0 mt-auto text-xs text-[#a2a2a2]"
						onClick={toggleForm}
					>
						<span className="underline">{LL.addOptionButton()}</span> +
					</Button>
				</div>

				<Card
					className="max-h-[300px] flex-1 overflow-y-auto p-5"
					loading={loading}
					bodyStyle={{ padding: 0 }}
				>
					{options.map((option) => (
						<div
							className="flex justify-between"
							key={`${variant}-${option.id}`}
						>
							<div>
								<Switch
									size="small"
									defaultChecked={option.active ?? false}
									className={"mr-2"}
									onChange={() => {
										toggleActive(option.id);
									}}
								/>
								{option.name}
							</div>

							{isLoadingDelete ? (
								<Spin />
							) : (
								<DeleteFilled
									className="cursor-pointer text-red-400"
									onClick={() => {
										deleteType(option.id);
									}}
								/>
							)}
						</div>
					))}
				</Card>
			</div>

			{showForm ? (
				<AddTypeForm
					onCreate={() => {
						setShowForm(false);
					}}
					variant={variant}
				/>
			) : null}
		</>
	);
}
