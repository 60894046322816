import {
	UseMutationOptions,
	type UseQueryOptions,
	useQuery,
} from "@tanstack/react-query";
import { z } from "zod";

import { TypeFormInputs } from "@/components/project-list/settings/AddTypeForm";
import { fetchClient } from "@/definitions";
import { Stage, stageSchema } from "@/utils/schemas/project";

type StageOptions = {
	projectId?: number | string;
};

export function projectStageQuery(options?: StageOptions) {
	const { projectId } = options ?? {};

	return {
		queryKey: ["projectStages", projectId],
		queryFn: async () => {
			const res = await fetchClient(
				`Stages${projectId ? `?projectId=${projectId}` : ""}`,
			);
			return z.array(stageSchema).parse(res);
		},
	} satisfies UseQueryOptions;
}

export function createStageQuery() {
	return {
		mutationKey: ["projectStages", "create"],
		mutationFn: async (data) => {
			const res = await fetchClient("Stages", {
				method: "POST",
				body: JSON.stringify(data),
			});

			return stageSchema.parse(res);
		},
	} satisfies UseMutationOptions<Stage, unknown, TypeFormInputs>;
}

export function toggleStageQuery() {
	return {
		mutationKey: ["projectStages", "toggle"],
		mutationFn: async (id: number) =>
			fetchClient(`Stages/ChangeStatus/${id}`, {
				method: "PATCH",
			}),
	} satisfies UseMutationOptions<void, unknown, number>;
}

export function deleteStageQuery() {
	return {
		mutationKey: ["projectStages", "delete"],
		mutationFn: (id: number) =>
			fetchClient(`Stages/${id}`, {
				method: "DELETE",
			}),
	} satisfies UseMutationOptions<void, unknown, number>;
}

export default function useProjectStage(options?: StageOptions) {
	return useQuery(projectStageQuery(options));
}
