import { Card, Switch, type SwitchProps, Typography } from "antd";

import { useI18nContext } from "@/i18n/i18n-react";
import cn from "@/utils/cn";

import Label from "./Label";

const { Paragraph } = Typography;

type StateToggleProps = { cardClassName?: string } & Omit<SwitchProps, "size">;

export default function StateToggle({
	cardClassName,
	className,
	...props
}: StateToggleProps) {
	const {
		LL: { ProjectModule: LL },
	} = useI18nContext();

	return (
		<Card
			bordered={false}
			className={cn(
				"rounded-none h-full bg-[#fafafa] shadow-none",
				cardClassName,
			)}
			bodyStyle={{
				padding: 0,
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				height: "75%",
			}}
		>
			<Label className="m-0">{LL.state.label()}</Label>

			<div className="flex items-center gap-2 mt-auto mb-0">
				<Paragraph className="m-0 font-thin text-[#a2a2a2]">
					{LL.state.inactive()}
				</Paragraph>

				<Switch
					size="small"
					className={cn("max-w-[45px]", className)}
					{...props}
				/>

				<Paragraph className="m-0 font-thin text-[#a2a2a2]">
					{LL.state.active()}
				</Paragraph>
			</div>
		</Card>
	);
}
