import { CloseOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Checkbox, Select, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
	Controller,
	SubmitErrorHandler,
	SubmitHandler,
	useForm,
} from "react-hook-form";
import { z } from "zod";

import { queryClient } from "@/App";
import Label from "@/components/Label";
import { USER_ESTABLISHMENT_ROLES, USER_ROLES } from "@/constants/enums";
import { useToast } from "@/context";
import useEstablishmentData from "@/hooks/query/useEstablishmentData";
import { userAccessRoleQuery, userQuery } from "@/hooks/query/useUserData";
import { useI18nContext } from "@/i18n/i18n-react";
import { type User } from "@/utils/schemas/user";
import whatHasChangedInArray from "@/utils/whatHasChangedInArray";

const inputs = z.object({
	isAdmin: z.boolean(),
	establishments: z.array(
		z.object({
			id: z.number(),
			role: z.number(),
		}),
	),
});
export type Inputs = z.infer<typeof inputs>;

type RolesCardProps = {
	user: User;
	handleChangeUser: (user: User | null) => void;
};

export default function RolesForm({ user, handleChangeUser }: RolesCardProps) {
	const {
		LL: {
			ProjectModule: {
				settings: LL,
				roles: { access },
			},
			toasts: ToastLL,
		},
	} = useI18nContext();

	const { toast } = useToast();

	const { data: establishments } = useEstablishmentData();

	const establishmentRolesOptions = Object.entries(
		USER_ESTABLISHMENT_ROLES,
	).map(([_, value]) => ({
		label: access[value === 0 ? "READER" : "READER_WRITER"](),
		value,
	}));

	const establishmentsCheckboxes = establishments?.map(({ name, id }) => ({
		id,
		label: name,
	}));

	const initialValues: Inputs = useMemo(
		() => ({
			isAdmin: user.role?.value === USER_ROLES.SUPER_ADMIN,
			establishments:
				user.establishments?.map(({ id, accessLevel }) => ({
					id,
					role: accessLevel!,
				})) ?? [],
		}),
		[user],
	);

	const { control, getValues, setValue, reset, handleSubmit } = useForm<Inputs>(
		{
			defaultValues: initialValues,
			resolver: zodResolver(inputs),
		},
	);

	const { mutate, isLoading } = useMutation({
		...userAccessRoleQuery(user.id),
		onSuccess: async () => {
			await queryClient.refetchQueries({
				queryKey: userQuery().queryKey,
			});

			reset(initialValues);

			toast.success({
				message: ToastLL.success(),
				description: ToastLL.updatedSuccessfully(),
			});
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	const onValid: SubmitHandler<Inputs> = ({ isAdmin, establishments }) => {
		const { added, deleted } = whatHasChangedInArray(
			establishments,
			initialValues.establishments,
		);

		const parsedData = {
			isAdmin,
			establishments: {
				added,
				deleted,
			},
		};

		mutate([parsedData, user]);
	};

	const onInvalid: SubmitErrorHandler<Inputs> = (data) => {
		console.error(data);
	};

	const [isUserAdmin, setIsUserAdmin] = useState(initialValues.isAdmin);

	useEffect(() => {
		reset(initialValues);
		setIsUserAdmin(getValues("isAdmin"));
	}, [user]);

	return (
		<form onSubmit={handleSubmit(onValid, onInvalid)}>
			<Card
				bordered={false}
				className="rounded-none bg-[#fafafa]"
				bodyStyle={{
					display: "flex",
					flexDirection: "column",
					paddingTop: 0,
					paddingBottom: 0,
					gap: "20px",
				}}
			>
				<div className="flex flex-row justify-between">
					<div>
						<Typography>
							<Typography.Title level={4} className="m-0">
								{user.name}
							</Typography.Title>

							{/* TODO: Render this depending on if logged user is an admin */}
							<Controller
								control={control}
								name="isAdmin"
								render={({ field: { value, onChange, ...field } }) => (
									<Checkbox
										checked={value}
										disabled={isLoading}
										onChange={(e) => {
											setIsUserAdmin(e.target.checked);
											onChange(e);
										}}
										{...field}
									>
										Super Admin
									</Checkbox>
								)}
							/>
						</Typography>
					</div>

					<CloseOutlined
						className="cursor-pointer self-start mt-2"
						onClick={() => {
							handleChangeUser(null);
						}}
					/>
				</div>

				<div className="flex flex-col">
					<Label className="m-0 mb-2">
						{LL.tabs.globalParameters.module()}
					</Label>

					<Select disabled defaultValue="Tutelle" />
				</div>

				<div className="flex flex-col">
					{!isUserAdmin ? (
						<>
							<Label className="m-0 mb-2">
								{LL.tabs.globalParameters.access()}
							</Label>

							<Card
								className="rounded-sm overflow-y-auto p-5"
								bodyStyle={{
									padding: 0,
									display: "flex",
									flexDirection: "column",
								}}
							>
								<div className="grid grid-cols-2">
									<Typography>
										<Typography.Paragraph className="text-[#a2a2a2]">
											Établissements
										</Typography.Paragraph>
									</Typography>

									<Typography>
										<Typography.Paragraph className="text-[#a2a2a2]">
											Droits d’accès
										</Typography.Paragraph>
									</Typography>
								</div>

								<Controller
									name="establishments"
									control={control}
									render={({ field }) => (
										<div className="flex flex-col gap-y-3">
											{establishmentsCheckboxes?.map(({ label, id }) => (
												<div key={id} className="grid grid-cols-2">
													<Checkbox
														className="flex items-center"
														disabled={isLoading}
														checked={field.value.some((est) => est.id === id)}
														onChange={(e) => {
															if (e.target.checked)
																setValue(
																	"establishments",
																	field.value.concat({
																		id,
																		role: USER_ESTABLISHMENT_ROLES.READER,
																	}),
																);
															else
																setValue(
																	"establishments",
																	field.value.filter((est) => est.id !== id),
																);
														}}
													>
														{label}
													</Checkbox>

													<Select
														disabled={
															!getValues("establishments").find(
																(est) => est.id === id,
															) || isLoading
														}
														value={
															field.value.find((est) => est.id === id)?.role
														}
														onChange={(value) => {
															setValue("establishments", [
																...field.value.filter((est) => est.id !== id),
																{
																	id,
																	role: value,
																},
															]);
														}}
														options={establishmentRolesOptions}
													/>
												</div>
											))}
										</div>
									)}
								/>
							</Card>
						</>
					) : null}

					<Button
						className="ml-auto mr-0 mt-5 self-end border-none bg-black text-white"
						loading={isLoading}
						htmlType="submit"
					>
						{LL.update()}
					</Button>
				</div>
			</Card>
		</form>
	);
}
