import { useMutation } from "@tanstack/react-query";
import { Layout, Typography } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { logIn } from "@/api";
import { ReactComponent as Logo } from "@/assets/LOGO.svg";
import LoginForm, {
	type LoginFormInputs,
} from "@/components/login-form/LoginForm";
import { UserContext, useToast } from "@/context";
import { routes } from "@/definitions";
import { useI18nContext } from "@/i18n/i18n-react";

export default function Login() {
	const { LL } = useI18nContext();
	const navigate = useNavigate();
	const { onLogIn: updateUserContext } = useContext(UserContext);
	const { toast } = useToast();

	const { mutate: onLogIn } = useMutation({
		mutationFn: (vars: LoginFormInputs) =>
			logIn(vars)
				.then((data) => {
					updateUserContext(data);
					toast.success({
						message: LL.toasts.success(),
						description: LL.toasts.welcome(),
					});
					navigate(routes.home());
				})
				.catch((e) => {
					toast.error({
						message: LL.toasts.error(),
						description:
							e instanceof Error ? e.message : LL.toasts.somethingIsNoYes(),
					});
				}),
	});

	return (
		<Layout className="flex min-h-screen flex-row border-b-[32px] border-[#005373]">
			<Layout className="flex flex-col items-center">
				<Layout className="flex max-w-screen-sm justify-center">
					<Typography className="text-center">
						<Typography.Title className="text-[#343434]">
							{LL.appName()}
						</Typography.Title>

						<Typography.Paragraph className="text-2xl text-[#888]">
							{LL.Login.signInToAccess()}
						</Typography.Paragraph>
					</Typography>

					<LoginForm onSubmit={onLogIn} />
				</Layout>

				<Typography className="mb-10 mt-auto text-center">
					<Typography.Paragraph className="text-[#888]">
						{LL.Login.createdBy()}
					</Typography.Paragraph>

					<Logo />
				</Typography>
			</Layout>

			<Layout className="flex max-w-screen-sm items-center justify-center bg-[url(/imgs/fond-accueil.png)] bg-cover bg-center bg-no-repeat lg:max-w-screen-md 2xl:max-w-screen-lg">
				<Typography className="max-w-lg">
					<Typography.Title className="uppercase text-white">
						{LL.Login.vanguardOfHealth()}
					</Typography.Title>

					<Typography.Paragraph className="text-xl text-white">
						{LL.Login.organisationDescription()}
					</Typography.Paragraph>
				</Typography>
			</Layout>
		</Layout>
	);
}
