import { PaginationProps, TableProps } from "antd";
import { useState } from "react";

import { ListRequest } from "@/utils/schemas";

type ParamsDefault = Record<string, string | string[] | number | number[]>;

export type UseRequestParams<T = ParamsDefault> = {
	initParams?: T;
	initOrderBy?: ListRequest["orderBy"];
	paginationProps?: PaginationProps;
};

export type UseRequestParamsReturn<TReq = ParamsDefault, TData = any> = {
	reqParams?: TReq;
	orderBy: ListRequest["orderBy"];
	afterPageChange: (fetchedItemsNumber: number) => void;
	setParams: (params: TReq) => void;
	setOrderBy: (orderBy: ListRequest["orderBy"]) => void;
	pagination: PaginationProps;
	onTableStateChange?: TableProps<TData>["onChange"];
};

export function useRequestParams<
	T extends ParamsDefault,
	TData extends Record<any, any> = any,
>(props?: UseRequestParams<T>): UseRequestParamsReturn<T, TData> {
	const { initParams, paginationProps = {}, initOrderBy = {} } = props ?? {};
	const { pageSize: initPageSize = 20, defaultCurrent = 1 } = paginationProps;

	const [params, setParams] = useState<T | undefined>(initParams);
	const [orderBy, setOrderBy] = useState<ListRequest["orderBy"]>(initOrderBy);
	const [index, setIndex] = useState(defaultCurrent);
	const [totalItems, setTotalItems] = useState(0);
	const [pageSize, setPageSize] = useState(initPageSize);

	const afterPageChange = (total: number) => {
		setTotalItems(total);
	};

	const onChange = (page: number) => {
		setIndex(page);
	};

	const onParamsChange = (params: T) => {
		setTotalItems(0);
		setParams(params);
	};

	const onShowSizeChange = (_size: number, next: number) => {
		setTotalItems(0);
		setPageSize(next);
	};

	const onTableStateChange: UseRequestParamsReturn<
		T,
		TData
	>["onTableStateChange"] = (...args) => {
		const [_, filters, sorter] = args;
		const { columnKey, order } = Array.isArray(sorter) ? sorter[0] : sorter;
		const hasOrder = typeof order !== "undefined";

		const filtersCopy = { ...filters };

		Object.keys(filters).forEach((key) => {
			if (!filters[key]) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete filtersCopy[key];
			}
		});

		setParams(filtersCopy as T);
		setOrderBy(
			hasOrder
				? {
						parameter: columnKey?.toString(),
						descending: order === "descend",
				  }
				: undefined,
		);
	};

	return {
		setParams: onParamsChange,
		afterPageChange,
		reqParams: params,
		orderBy,
		setOrderBy,
		onTableStateChange,
		pagination: {
			onChange,
			defaultPageSize: pageSize,
			current: index,
			total: totalItems,
			pageSize,
			onShowSizeChange,
		},
	};
}
