import {
	CalendarOutlined,
	CloseOutlined,
	HourglassOutlined,
	TagOutlined,
} from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, DatePicker, Form, Input, Select } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import {
	Controller,
	SubmitErrorHandler,
	SubmitHandler,
	useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { ReactComponent as PenAndPaperOutlined } from "@/assets/icons/pen-and-paper.svg";
import { useToast } from "@/context";
import { createProjectQuery } from "@/hooks/query/useProjectData";
import useProjectStage from "@/hooks/query/useProjectStage";
import { useI18nContext } from "@/i18n/i18n-react";
import projectSchema from "@/utils/schemas/project";

import Label from "../Label";
import StateToggle from "../StateToggle";

const { Item } = Form;

const formInputs = projectSchema
	.pick({ name: true, description: true, active: true, dueDate: true })
	.extend({
		stage: z.number(),
	});
export type CreateProjectForm = z.infer<typeof formInputs>;

type CreateProjectModalProps = {
	toggleCreateProjectModal: () => void;
};

export default function CreateProjectModal({
	toggleCreateProjectModal,
}: CreateProjectModalProps) {
	const {
		LL: {
			ProjectModule: { projectForm: LL },
			toasts: ToastLL,
		},
	} = useI18nContext();

	const navigate = useNavigate();
	const { toast } = useToast();

	//mocked project id to list only "active" stages
	const { data: stages } = useProjectStage({ projectId: 2137 });

	const stageOptions = useMemo(() => {
		return (
			stages?.map(({ id, name }) => ({
				label: name,
				value: id,
			})) || []
		);
	}, [stages]);

	const { control, handleSubmit, setValue, reset } = useForm<CreateProjectForm>(
		{
			resolver: zodResolver(formInputs),
		},
	);

	useEffect(() => {
		reset({
			name: "Nowy projekt",
			description: "",
			dueDate: dayjs().add(14, "day").toISOString(),
			active: true,
			stage: stageOptions[0]?.value,
		});
	}, [stageOptions]);

	const { mutate: createProject, isLoading } = useMutation({
		...createProjectQuery(),
		onSuccess: (data) => {
			toast.success({
				message: ToastLL.success(),
				description: ToastLL.projectCreatedSuccessfully(),
			});
			if (data) navigate(`/projects/${data.id}`);
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	const onValid: SubmitHandler<CreateProjectForm> = (data) => {
		const { dueDate } = data;

		if (dueDate) {
			const date = dayjs(dueDate).format();

			createProject({ ...data, dueDate: date });
		} else createProject(data);
	};

	const onInvalid: SubmitErrorHandler<CreateProjectForm> = (data) => {
		console.error(data);
	};

	return (
		<Card
			className="min-h-[475px] border-none overflow-hidden"
			bodyStyle={{ padding: 0 }}
		>
			<form onSubmit={handleSubmit(onValid, onInvalid)}>
				<div className="flex justify-between bg-black text-white p-5">
					<div>
						<span>{LL.createProject.title()}</span>
					</div>

					<CloseOutlined
						className="cursor-pointer"
						onClick={toggleCreateProjectModal}
					/>
				</div>

				<div className="grid grid-cols-2 p-5 gap-2">
					<div>
						<Label icon={<TagOutlined />}>{LL.projectTitle()}</Label>

						<Controller
							name="name"
							control={control}
							render={({ field, fieldState: { invalid, error } }) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input size="middle" type="text" {...field} />
								</Item>
							)}
						/>
					</div>

					<div className="flex flex-col">
						<Label icon={<CalendarOutlined />}>{LL.dueDate()}</Label>

						<Controller
							name="dueDate"
							control={control}
							render={({
								field: { name, value, onBlur },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<DatePicker
										locale={locale}
										name={name}
										value={dayjs(value)}
										disabledDate={(date) => date.toDate() < new Date()}
										onChange={(value) => {
											setValue("dueDate", value?.toISOString() ?? "");
										}}
										onBlur={onBlur}
										className="w-full"
									/>
								</Item>
							)}
						/>
					</div>

					<div className="flex flex-1 flex-col col-span-2 mb-5">
						<Label icon={<PenAndPaperOutlined className="self-center" />}>
							{LL.description.label()}
						</Label>

						<Controller
							name="description"
							control={control}
							render={({ field: { value, ...field } }) => (
								<TextArea
									className="h-full flex-1"
									placeholder={LL.description.placeholder()}
									value={value ?? ""}
									{...field}
								/>
							)}
						/>
					</div>

					<StateToggle
						cardClassName="pt-3 pl-3"
						onChange={(value) => {
							setValue("active", value);
						}}
					/>

					<div className="flex flex-col">
						<Label icon={<HourglassOutlined />}>{LL.stage()}</Label>

						<Controller
							name="stage"
							control={control}
							render={({ field }) => (
								<Select options={stageOptions} placeholder="-" {...field} />
							)}
						/>
					</div>

					<div className="col-span-2 flex justify-end">
						<Button
							type="primary"
							loading={isLoading}
							htmlType="submit"
							className="px-8 h-9 bg-[#ff9043]"
						>
							{LL.createProject.createButton()}
						</Button>
					</div>
				</div>
			</form>
		</Card>
	);
}
