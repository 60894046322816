import { z } from "zod";

export const listRequestSchema = z.object({
	take: z.number().optional(),
	skip: z.number().optional(),
	orderBy: z
		.object({
			parameter: z.string().optional(),
			descending: z.boolean().optional(),
		})
		.optional(),
});
export type ListRequest = z.infer<typeof listRequestSchema>;

export const fileForEntity = z.union([
	z.literal("Alert"),
	z.literal("Project"),
]);
export type FileForEntity = z.infer<typeof fileForEntity>;

export const responseSchema = z.object({
	total: z.number(),
	pageSize: z.number(),
});
