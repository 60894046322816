import { type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { fetchClient } from "@/definitions";
import mutationKeys from "@/definitions/mutation-keys";
import establishment, {
	EstablishmentType,
	externalEstablishment,
} from "@/utils/schemas/establishment";

type Options = {
	type?: EstablishmentType;
	projectId?: number | string;
};

export function establishmentQuery(options?: Options) {
	const { type = "internal", projectId } = options ?? {};

	return {
		queryKey: mutationKeys.establishments([type, projectId]),
		queryFn: async () => {
			const isExternal = type === "external";

			const res = await fetchClient(
				`Establishment${isExternal ? "/External" : ""}${
					projectId ? `?projectId=${projectId}` : ""
				}`,
			);

			return z
				.array(isExternal ? externalEstablishment : establishment)
				.parse(res);
		},
	} satisfies UseQueryOptions;
}

export default function useEstablishmentData(options?: Options) {
	return useQuery(establishmentQuery(options));
}
