import { useContext, useEffect, useMemo } from "react";

import { UserContext } from "@/context";
import { persistenceKeys, storageKeys } from "@/definitions";

type Key = keyof typeof persistenceKeys;

export function useParamsPersistence<TParams = unknown>(key: Key) {
	const { authToken } = useContext(UserContext);
	const setPersistedParams = (params: TParams, keyOverride = key) => {
		localStorage.setItem(keyOverride, JSON.stringify(params));
	};

	const getPersistedParams = (keyOverride = key) => {
		const params = localStorage.getItem(keyOverride);
		if (params) {
			return JSON.parse(params) as TParams;
		}
		return;
	};

	const clearPersistedParams = (keyOverride = key) => {
		localStorage.removeItem(keyOverride);
	};

	const persistedParams = useMemo(() => {
		return getPersistedParams();
	}, []);

	useEffect(() => {
		return () => {
			!localStorage.getItem(storageKeys.authToken) && clearPersistedParams();
		};
	}, []);

	return {
		persistedParams,
		setPersistedParams,
		getPersistedParams,
		clearPersistedParams,
	};
}
