import { Dispatch, PropsWithChildren, createContext, useReducer } from "react";

type SettingsTab = "financement" | "project" | "stage" | null;

function settingsTabReducer(_tab: SettingsTab, action: SettingsTab) {
	switch (action) {
		case "financement":
			return "financement";
		case "project":
			return "project";
		case "stage":
			return "stage";
		default:
			return null;
	}
}

type ModuleSettingsContext = {
	tab: SettingsTab;
	dispatchTab: Dispatch<SettingsTab>;
};

export const ModuleSettingsContext = createContext<ModuleSettingsContext>(
	{} as ModuleSettingsContext,
);

export function ModuleSettingsDrawerProvider({ children }: PropsWithChildren) {
	const [tab, dispatchTab] = useReducer(settingsTabReducer, null);

	return (
		<ModuleSettingsContext.Provider
			value={{
				tab,
				dispatchTab,
			}}
		>
			{children}
		</ModuleSettingsContext.Provider>
	);
}
