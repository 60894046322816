import { CloseOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, Form, Input, Popconfirm } from "antd";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import Label from "@/components/Label";
import StateToggle from "@/components/StateToggle";
import { useI18nContext } from "@/i18n/i18n-react";
import {
	type Establishment,
	EstablishmentType,
	ExternalEstablishment,
	establishmentForm,
	externalEstablishmentForm,
} from "@/utils/schemas/establishment";

const { Item } = Form;

type EstablishmentFormProps = {
	handleChangeSelectedEstablishment: (
		establishment: Establishment | ExternalEstablishment | null,
	) => void;
	initValues: Partial<Establishment> | Partial<ExternalEstablishment>;
	onSubmit: (data: Establishment | ExternalEstablishment) => void;
	type: EstablishmentType;
	isLoading?: boolean;
	onDelete?: (id: number) => void;
};

export default function EstablishmentForm({
	handleChangeSelectedEstablishment,
	initValues,
	onSubmit,
	isLoading,
	type,
	onDelete,
}: EstablishmentFormProps) {
	const {
		LL: {
			ProjectModule: { settings: LL },
			...t
		},
	} = useI18nContext();

	const isExternal = type === "external";
	const formInputs = isExternal ? externalEstablishmentForm : establishmentForm;

	const { control, handleSubmit, reset } = useForm<
		Establishment | ExternalEstablishment
	>({
		resolver: zodResolver(formInputs),
		defaultValues: initValues,
	});

	useEffect(() => {
		reset(initValues);
	}, [initValues]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Card
				bordered={false}
				className="rounded-none bg-[#fafafa]"
				bodyStyle={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<CloseOutlined
					className="self-end cursor-pointer mt-2"
					onClick={() => {
						handleChangeSelectedEstablishment(null);
					}}
				/>

				<div className="grid grid-cols-2 gap-2">
					<div
						className={`grid grid-cols-2 gap-2 grid-rows-${isExternal ? 1 : 2}`}
					>
						<div className="col-span-2">
							<Label className="ml-0" required>
								{LL.tabs.establishments.establishmentName()}
							</Label>
							<Controller
								name="name"
								control={control}
								render={({ field, fieldState: { invalid, error } }) => (
									<Item
										validateStatus={invalid ? "error" : "success"}
										help={error?.message ?? ""}
									>
										<Input {...field} />
									</Item>
								)}
							/>
						</div>

						{!isExternal && (
							<div>
								<Label className="ml-0">
									{LL.tabs.establishments.internalCode()}
								</Label>
								<Controller
									name="internalCode"
									control={control}
									render={({
										field: { value, ...field },
										fieldState: { invalid, error },
									}) => (
										<Item
											validateStatus={invalid ? "error" : "success"}
											help={error?.message ?? ""}
										>
											<Input value={value ?? ""} {...field} />
										</Item>
									)}
								/>
							</div>
						)}

						{!isExternal && (
							<div>
								<Label className="ml-0">{LL.tabs.establishments.siret()}</Label>
								<Controller
									name="siret"
									control={control}
									render={({
										field: { value, ...field },
										fieldState: { invalid, error },
									}) => (
										<Item
											validateStatus={invalid ? "error" : "success"}
											help={error?.message ?? ""}
										>
											<Input value={value ?? ""} {...field} />
										</Item>
									)}
								/>
							</div>
						)}
					</div>

					<div
						className={`grid grid-cols-2 gap-2 grid-rows-${isExternal ? 1 : 2}`}
					>
						<div className="col-span-2">
							<Label className="ml-0">
								{LL.tabs.establishments.companyName()}
							</Label>
							<Controller
								name="companyName"
								control={control}
								render={({
									field: { value, ...field },
									fieldState: { invalid, error },
								}) => (
									<Item
										validateStatus={invalid ? "error" : "success"}
										help={error?.message ?? ""}
									>
										<Input value={value ?? ""} {...field} />
									</Item>
								)}
							/>
						</div>

						{!isExternal && (
							<div>
								<Label className="ml-0">
									{LL.tabs.establishments.finessJurdique()}
								</Label>
								<Controller
									name="leagalFINESS"
									control={control}
									render={({
										field: { value, ...field },
										fieldState: { invalid, error },
									}) => (
										<Item
											validateStatus={invalid ? "error" : "success"}
											help={error?.message ?? ""}
										>
											<Input value={value ?? ""} {...field} />
										</Item>
									)}
								/>
							</div>
						)}

						{!isExternal && (
							<div>
								<Label className="ml-0">
									{LL.tabs.establishments.finessGeo()}
								</Label>
								<Controller
									name="geoFINESS"
									control={control}
									render={({
										field: { value, ...field },
										fieldState: { invalid, error },
									}) => (
										<Item
											validateStatus={invalid ? "error" : "success"}
											help={error?.message ?? ""}
										>
											<Input value={value ?? ""} {...field} />
										</Item>
									)}
								/>
							</div>
						)}
					</div>
				</div>

				<div>
					<Label className="ml-0">{LL.tabs.establishments.adress()}</Label>
					<Controller
						name="address"
						control={control}
						render={({
							field: { value, ...field },
							fieldState: { invalid, error },
						}) => (
							<Item
								validateStatus={invalid ? "error" : "success"}
								help={error?.message ?? ""}
							>
								<Input value={value ?? ""} {...field} />
							</Item>
						)}
					/>
				</div>

				<div className="flex gap-2 flex-row">
					<div className="max-w-[12ch]">
						<Label className="ml-0">
							{LL.tabs.establishments.postalCode()}
						</Label>
						<Controller
							name="code"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>

					<div className="flex-1">
						<Label className="ml-0">{LL.tabs.establishments.city()}</Label>
						<Controller
							name="city"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>
				</div>

				<div className="grid grid-cols-2 gap-2">
					<div>
						<Label className="ml-0">{LL.tabs.establishments.director()}</Label>
						<Controller
							name="deputyDirector"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>

					<div>
						<Label className="ml-0">
							{LL.tabs.establishments.legalGuardian()}
						</Label>
						<Controller
							name="establishmentLegalManager"
							control={control}
							render={({
								field: { value, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<Input value={value ?? ""} {...field} />
								</Item>
							)}
						/>
					</div>
				</div>

				<div className={"mt-5 flex row justify-end items-center"}>
					<div className="mr-auto">
						<Controller
							name="active"
							control={control}
							render={({
								field: { value, ref: _ref, ...field },
								fieldState: { invalid, error },
							}) => (
								<Item
									validateStatus={invalid ? "error" : "success"}
									help={error?.message ?? ""}
								>
									<StateToggle checked={!!value} {...field} />
								</Item>
							)}
						/>
					</div>

					{onDelete && !!initValues.id && (
						<Popconfirm
							title={t.popovers.deleteItemConfirm()}
							onConfirm={() => {
								initValues.id && onDelete(initValues.id);
							}}
							okText={t.buttons.yes()}
							cancelText={t.buttons.no()}
						>
							<Button
								type={"text"}
								color={"error"}
								className={"mr-2"}
								loading={isLoading}
							>
								{LL.delete()}
							</Button>
						</Popconfirm>
					)}

					<Button
						htmlType={"submit"}
						className="mr-0border-none bg-black text-white"
						loading={isLoading}
					>
						{LL.update()}
					</Button>
				</div>
			</Card>
		</form>
	);
}
