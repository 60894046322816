import {
	DeleteFilled,
	EyeOutlined,
	FormOutlined,
	LoadingOutlined,
} from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { type HTMLAttributes, useMemo } from "react";

import { queryClient } from "@/App";
import { useToast } from "@/context";
import { deleteAlertQuery } from "@/hooks/query/useAlertData";
import { useI18nContext } from "@/i18n/i18n-react";
import cn from "@/utils/cn";
import { type Alert } from "@/utils/schemas";

type AlertProps = {
	projectId: number;
	alert: Alert;
	canEdit?: boolean;
	handleChangeCurrentAlert: (alert: Alert | null) => void;
} & HTMLAttributes<HTMLDivElement>;

export default function AlertComponent({
	alert,
	className,
	handleChangeCurrentAlert,
	canEdit,
}: AlertProps) {
	const {
		LL: { toasts: ToastLL },
	} = useI18nContext();

	const { toast } = useToast();

	const date = useMemo(
		() => new Date(alert.triggerDate).toLocaleString("fr-CH").split(" ")[0],
		[alert.triggerDate],
	);

	const { mutate: deleteAlert, isLoading: isDeleteLoading } = useMutation({
		...deleteAlertQuery(alert.id),
		onSuccess: async () => {
			await queryClient.invalidateQueries();
			toast.success({
				message: ToastLL.success(),
				description: ToastLL.createdSuccessfully(),
			});
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	// TODO: uncomment once duplicating alerts'll be ready
	// const { mutate: copyAlert, isLoading: isCopyingLoading } = useMutation({
	// 	...createAlertQuery(),
	// 	onSuccess: async () => {
	// 		void queryClient.refetchQueries(["project", projectId.toString()]);
	// 		await queryClient.invalidateQueries();
	// 	},
	// });

	function handleDeleteAlert() {
		deleteAlert();
	}

	return (
		<div
			className={cn(
				"flex items-center justify-between py-5 pl-4 pr-3",
				className,
			)}
		>
			<div className="flex flex-col">
				<div className="flex gap-[0.5rem]">
					<span className="font-bold overflow-ellipsis overflow-hidden whitespace-nowrap max-w-[6rem] md:max-w-[8rem] lg:max-w-[14rem] xl:max-w-sm">
						{alert.title}
					</span>

					<span>-</span>

					<span className="font-bold">{date}</span>
				</div>

				<span>{alert.users?.map((user) => user.name).join(", ")}</span>
			</div>

			<div className="flex flex-row gap-1 items-end">
				{/*<DuplicateFilled*/}
				{/*	className="cursor-pointer text-[#5e9ad3]"*/}
				{/*	onClick={() => copyAlert(alert, projectId)}*/}
				{/*	width={16}*/}
				{/*	height={16}*/}
				{/*/>*/}

				{canEdit ? (
					<FormOutlined
						className="cursor-pointer text-lg text-[#5e9ad3]"
						onClick={() => {
							handleChangeCurrentAlert(alert);
						}}
					/>
				) : (
					<EyeOutlined
						className="cursor-pointer text-lg text-[#5e9ad3]"
						onClick={() => {
							handleChangeCurrentAlert(alert);
						}}
					/>
				)}

				{isDeleteLoading ? (
					<LoadingOutlined className="text-lg text-[#5e9ad3]" />
				) : (
					canEdit && (
						<DeleteFilled
							className="cursor-pointer text-lg text-[#5e9ad3]"
							onClick={handleDeleteAlert}
						/>
					)
				)}
			</div>
		</div>
	);
}
