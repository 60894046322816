import {
	type UseMutationOptions,
	type UseQueryOptions,
	useQuery,
} from "@tanstack/react-query";
import { z } from "zod";

import { Inputs as UserRoleForm } from "@/components/settings/global/RolesForm";
import { fetchClient } from "@/definitions";
import mutationKeys from "@/definitions/mutation-keys";
import userSchema, {
	type User,
	type UserExternal,
	type UserType,
	externalUser,
} from "@/utils/schemas/user";

export type UserQueryOptions = {
	type: UserType;
	onlyUsersWithAccounts?: boolean;
	projectId?: number | string;
};

export function userQuery(
	id?: string | null,
	options: UserQueryOptions = { type: "internal" },
) {
	return {
		queryKey: mutationKeys.users(options, [id]),
		queryFn: async (): Promise<
			(typeof options.type extends "internal" ? User[] : UserExternal[]) | null
		> => {
			const service =
				options.type === "internal" ? "Users" : "ExternalIntervenants";

			//yeah, ugly AF
			const res = await fetchClient(
				`${service}${id ?? ""}/?${
					options.onlyUsersWithAccounts ? "Adid=true" : ""
				}${
					options.projectId
						? `${options.onlyUsersWithAccounts ? "&" : ""}projectId=${
								options.projectId
						  }`
						: ""
				}`,
				{},
			);

			return z
				.array(options.type === "internal" ? userSchema : externalUser)
				.parse(res);
		},
	} satisfies UseQueryOptions;
}

type UserAccessRoleMutationData = Omit<UserRoleForm, "establishments"> & {
	establishments: {
		added: UserRoleForm["establishments"];
		deleted: UserRoleForm["establishments"];
	};
};

export function userAccessRoleQuery(id: number) {
	return {
		mutationKey: ["user", "roles", id],
		mutationFn: async ([form, user]) => {
			await fetchClient(`Users/${id}`, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ isAdmin: form.isAdmin, ...user }),
			});

			// Delete establishments
			await Promise.all(
				form.establishments.deleted.map(({ id: establishmentId }) =>
					fetchClient(`Users/${id}/Establishment/${establishmentId}`, {
						method: "DELETE",
					}),
				),
			);

			// Adde new ones
			await Promise.all(
				form.establishments.added.map(({ id: establishmentId, role }) =>
					fetchClient(`Users/${id}/Establishment/${establishmentId}`, {
						method: "PATCH",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ level: role }),
					}),
				),
			);

			return null;
		},
	} satisfies UseMutationOptions<
		User | null,
		unknown,
		[UserAccessRoleMutationData, User]
	>;
}

export default function useUserData(
	id?: string | null,
	options: UserQueryOptions = {
		type: "internal",
		onlyUsersWithAccounts: false,
	},
) {
	return useQuery(userQuery(id, options));
}
