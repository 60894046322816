import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { PropsWithChildren, createContext, useContext } from "react";

type ToastContext = {
	toast: NotificationInstance;
};

export const ToastContext = createContext<ToastContext>({} as ToastContext);

export function ToastProvider({ children }: PropsWithChildren) {
	const [api, contextHolder] = notification.useNotification();

	return (
		<ToastContext.Provider
			value={{
				toast: api,
			}}
		>
			{contextHolder}
			{children}
		</ToastContext.Provider>
	);
}

export function useToast() {
	return useContext(ToastContext);
}
