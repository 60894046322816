import {
	PlusCircleOutlined,
	SettingFilled,
	SettingOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { type QueryClient } from "@tanstack/react-query";
import {
	Button,
	ConfigProvider,
	Drawer,
	Layout,
	Menu,
	MenuProps,
	Typography,
} from "antd";
import { useContext, useMemo } from "react";
import { type LoaderFunctionArgs, Outlet, useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "@/assets/LOGO.svg";
import Establishments from "@/components/settings/establishments";
import Global from "@/components/settings/global";
import Users from "@/components/settings/users";
import { DrawerContext, MenuItem, UserContext, menuKeys } from "@/context";
import { routes } from "@/definitions";
import { userQuery } from "@/hooks/query/useUserData";
import { useI18nContext } from "@/i18n/i18n-react";

export function loader(queryClient: QueryClient) {
	return async function (_args: LoaderFunctionArgs) {
		const users =
			queryClient.getQueryData(userQuery().queryKey) ??
			(await queryClient.fetchQuery(userQuery()));

		return users;
	};
}

export default function NavBar() {
	const { LL } = useI18nContext();
	const navigate = useNavigate();
	const { onLogOut, isAdmin } = useContext(UserContext);

	function handleRedirect() {
		navigate("/");
	}

	const { isDrawerOpened, toggleDrawer, currentTab, setCurrentTab } =
		useContext(DrawerContext);
	function handleToggleDrawer() {
		toggleDrawer();
	}

	const menuItems: MenuItem[] = useMemo(
		() => [
			{
				key: "global",
				label: LL.ProjectModule.settings.tabs.globalParameters.label(),
				icon: <SettingOutlined />,
			},
			{
				key: "users",
				label: LL.ProjectModule.settings.tabs.users.label(),
				icon: <UserOutlined />,
			},
			{
				key: "contractors",
				label: LL.ProjectModule.settings.tabs.users.contractorsLabel(),
				icon: <UserOutlined />,
			},
			{
				key: "establishments",
				label: LL.ProjectModule.settings.tabs.establishments.label(),
				icon: <PlusCircleOutlined />,
			},
			{
				key: "external-establishments",
				label: LL.ProjectModule.settings.tabs.establishments.externalLabel(),
				icon: <PlusCircleOutlined />,
			},
		],
		[],
	);

	const handleChangeTab: MenuProps["onClick"] = (e) => {
		const key = menuKeys.parse(e.key);
		setCurrentTab(key);
	};

	const logout = () => {
		onLogOut();
	}

	return (
		<Layout className="min-h-screen border-b-[32px] border-[#005373]">
			<Layout.Header className="flex h-24 items-center justify-between bg-black p-4">
				<Logo
					className="h-12 cursor-pointer fill-white"
					onClick={handleRedirect}
				/>

				<Typography className="hidden md:block">
					<Typography.Title className="m-0 text-white">
						{LL.appName()}
					</Typography.Title>
				</Typography>

				<div className="flex">
					<Button type="link" className="flex text-white" onClick={logout}>
						{LL.Login.logOut()}
					</Button>

					{isAdmin && (
						<Button
							type="link"
							className="flex text-white"
							onClick={handleToggleDrawer}
						>
							{LL.settings()}
							<SettingFilled className="text-lg" />
						</Button>
					)}
				</div>
			</Layout.Header>

			<Outlet />

			<ConfigProvider
				theme={{
					components: {
						Layout: {
							headerBg: "#e8e8f4",
							siderBg: "#e8e8f4",
						},
						Menu: {
							colorBgContainer: "#e8e8f4",
							colorSplit: "#e8e8f4",
							colorPrimary: "#343434",
						},
					},
				}}
			>
				<Drawer
					open={isDrawerOpened}
					contentWrapperStyle={{
						borderTopLeftRadius: "10px",
						overflow: "hidden",
					}}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					getContainer={document.getElementById("root")!}
					width={900}
					bodyStyle={{ padding: 0, display: "flex" }}
					closable={false}
					onClose={handleToggleDrawer}
				>
					<Layout className="bg-white">
						<Layout.Sider width={250} className="p-5 pr-3 pt-12">
							<Typography>
								<Typography.Title level={3} className="ml-5">
									{LL.ProjectModule.settings.title()}
								</Typography.Title>
							</Typography>

							<Menu
								className="text-sm text-[#343434]"
								selectedKeys={[currentTab]}
								onClick={handleChangeTab}
								items={menuItems}
							/>
						</Layout.Sider>

						<Layout.Content className="flex flex-col p-5 overflow-y-auto">
							{(() => {
								switch (currentTab) {
									case "global":
										return <Global />;
									case "users":
										return <Users type="internal" />;
									case "contractors":
										return <Users type="external" />;
									case "establishments":
										return <Establishments type="internal" />;
									case "external-establishments":
										return <Establishments type="external" />;
								}
							})()}
						</Layout.Content>
					</Layout>
				</Drawer>
			</ConfigProvider>
		</Layout>
	);
}
