import {
	CloseOutlined,
	DownOutlined,
	EyeOutlined,
	FormOutlined,
	InfoCircleOutlined,
	PrinterFilled,
	SearchOutlined,
	UpOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	Button,
	Card,
	ConfigProvider,
	Input,
	Layout,
	Modal,
	Popover,
	Space,
	Table,
	Tag,
	Typography,
} from "antd";
import {
	type Key,
	type KeyboardEvent,
	useContext,
	useEffect,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { getProjects, getProjectsPdf } from "@/api";
import AlertColumn from "@/components/project-list/AlertColumn";
import CreateProjectModal from "@/components/project-list/CreateProjectModal";
import {
	USER_ESTABLISHMENT_ROLES,
	USER_PROJECT_ROLES,
} from "@/constants/enums";
import { UserContext } from "@/context";
import { persistenceKeys } from "@/definitions";
import mutationKeys from "@/definitions/mutation-keys";
import {
	UseRequestParams,
	useParamsPersistence,
	useRequestParams,
} from "@/hooks";
import useEstablishmentData from "@/hooks/query/useEstablishmentData";
import useProjectStage from "@/hooks/query/useProjectStage";
import { useI18nContext } from "@/i18n/i18n-react";
import getMediaQuery from "@/utils/getMediaSize";
import { type Project, ProjectListRequest } from "@/utils/schemas/project";
import truncateString from "@/utils/truncateString";

const DESCRIPTION_MAX_LENGTH = (() => {
	const mediaSize = getMediaQuery();

	switch (mediaSize) {
		case "xxl":
			return 180;
		case "xl":
		case "lg":
			return 90;
		case "md":
		case "sm":
		case "zero":
			return 50;
	}
})();

type AntTableProps = Parameters<typeof Table>[number];
function calculateScrollsForTable(): AntTableProps["scroll"] {
	const mediaSize = getMediaQuery();

	switch (mediaSize) {
		case "xxl":
		case "xl":
			return {
				x: true,
				y: 700,
			};
		case "lg":
			return {
				x: true,
				y: 490,
			};
		case "md":
		case "sm":
		case "zero":
			return {
				x: true,
				y: 490,
			};
	}
}

const scroll = calculateScrollsForTable();
export default function ProjectsTable() {
	const {
		LL: {
			ProjectModule: {
				projectList: { projectTable: LL },
			},
			...t
		},
	} = useI18nContext();

	const { persistedParams, setPersistedParams } = useParamsPersistence<
		UseRequestParams<Partial<ProjectListRequest>>
	>(persistenceKeys.projectsListParams);

	const {
		afterPageChange,
		orderBy,
		reqParams,
		setParams,
		pagination: {total, ...pagination},
		onTableStateChange,
	} = useRequestParams<Partial<ProjectListRequest>, Project>(persistedParams);

	const navigate = useNavigate();
	const { isAdmin } = useContext(UserContext);

	const [search, setSearch] = useState(reqParams?.name ?? "");

	const { data: establishments } = useEstablishmentData();
	const { data: stages } = useProjectStage();

	//state of table filters, sorting & pagination is saved to local storage here
	useEffect(() => {
		setPersistedParams({
			initOrderBy: orderBy,
			paginationProps: pagination,
			initParams: reqParams,
		});
	}, [orderBy, pagination, reqParams]);

	const { data, isFetching } = useQuery({
		queryKey: mutationKeys.projects({ orderBy, pagination, reqParams }),
		queryFn: () =>
			getProjects({ reqParams, orderBy, pagination }).then((data) => {
				afterPageChange(data.total || 0);
				return data;
			}),
	});

	const { mutate: generatePdf } = useMutation({
		mutationFn: () => getProjectsPdf({ reqParams, orderBy }),
	});

	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

	const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

	function toggleCreateProjectModal() {
		setShowCreateProjectModal((prev) => !prev);
	}

	function onSelectChange(newSelectedRowKeys: Key[]) {
		setSelectedRowKeys(newSelectedRowKeys);
	}

	function handleSelection(id: Key) {
		const isSelected = selectedRowKeys.some((key) => id === key);

		const newSelectedRowKeys = isSelected
			? selectedRowKeys.filter((key) => key !== id)
			: [...selectedRowKeys, id];

		setSelectedRowKeys(newSelectedRowKeys);
	}

	function handleRedirectoToProjectEditFrom(id: number) {
		navigate(`/projects/${id}`);
	}

	function handleOnSearchKeyDown(e: KeyboardEvent<HTMLInputElement>) {
		switch (e.key) {
			case "Enter":
				onSearch(search);
				break;
		}
	}

	const onSearch = (val: string) => {
		const params = { ...(reqParams ?? {}) };
		if (val) {
			params.name = val;
		} else {
			setSearch(val);
			delete params.name;
		}
		setParams(params);
	};

	const canEdit = (accessLevel?: Project["accessLevel"]) => {
		return isAdmin || accessLevel === USER_ESTABLISHMENT_ROLES.READER_WRITER;
	};

	const getColumnSortingOrder = (key: string) => {
		const isSortedByCurrentColumn = orderBy?.parameter === key;
		if (isSortedByCurrentColumn) {
			return orderBy.descending ? "descend" : "ascend";
		}
		return null;
	};

	return (
		<ConfigProvider
			theme={{
				components: {
					Checkbox: {
						colorPrimary: "#d0d0d0",
						colorPrimaryHover: "#c0c0c0",
					},
					Table: {
						controlItemBgActive: "rgba(0, 0, 0, 0)",
						controlItemBgActiveHover: "rgba(0, 0, 0, 0)",
					},
				},
			}}
		>
			<Card className="w-full" bordered={false}>
				<Layout className="flex flex-row justify-between bg-white">
					<Typography>
						<Typography.Title level={3}>{LL.title()}</Typography.Title>
					</Typography>

					<Space size="large">
						{reqParams && !!Object.keys(reqParams).length && (
							<Button
								className={"p-0"}
								type={"text"}
								onClick={() => {
									setParams({});
									setSearch("");
								}}
								icon={<CloseOutlined className="cursor-pointer" />}
							>
								{t.buttons.resetFilters()}
							</Button>
						)}
						<Space.Compact className="border-b border-black">
							<Input
								onChange={(ev) => {
									setSearch(ev.target.value);
								}}
								value={search}
								onKeyDown={handleOnSearchKeyDown}
								placeholder={LL.searchProjects()}
								bordered={false}
							/>

							<Button
								onClick={() => {
									onSearch("");
								}}
								type={"text"}
								className={"p-0"}
								icon={
									!search ? (
										<SearchOutlined className="mt-1 h-6 text-lg" />
									) : (
										<CloseOutlined className="mt-1 h-6 text-lg" />
									)
								}
							/>
						</Space.Compact>

						{canEdit() && (
							<Button
								className="border-none bg-[#ff9043] text-white"
								onClick={toggleCreateProjectModal}
							>
								{LL.createProjectButton()} +
							</Button>
						)}
					</Space>
				</Layout>

				<Table
					dataSource={data?.projects}
					scroll={scroll}
					loading={isFetching}
					rowSelection={{
						selectedRowKeys: selectedRowKeys,
						onChange: onSelectChange,
						columnWidth: "25px",
						renderCell: (selected, { id: key }: Project) =>
							selected ? (
								<UpOutlined
									onClick={() => {
										handleSelection(key);
									}}
								/>
							) : (
								<DownOutlined
									onClick={() => {
										handleSelection(key);
									}}
								/>
							),
					}}
					rowKey="id"
					bordered={false}
					pagination={{total, ...pagination}}
					onChange={onTableStateChange}
				>
					<Table.Column
						key="establishmentIds"
						title={LL.columns.establishments()}
						width={200}
						dataIndex="establishments"
						filteredValue={reqParams?.establishmentIds}
						filters={establishments?.map(({ name, id }) => ({
							value: id,
							text: name,
						}))}
						filterMode={"menu"}
						render={(
							establishments: Project["establishments"],
							{ id }: Project,
						) => {
							const areEstablishmentsEmpty = !establishments?.length;
							if (areEstablishmentsEmpty)
								return <span className="font-bold text-gray-400">-</span>;

							const isSelected = selectedRowKeys.some((key) => id === key);

							if (isSelected)
								return establishments.map(({ name }) => name).join(", ");

							return establishments.length === 1
								? establishments[0].name
								: `${establishments[0].name}, +${establishments.length - 1}`;
						}}
					/>

					<Table.Column
						key="created"
						title={LL.columns.creationDate()}
						width={150}
						dataIndex="created"
						sorter
						sortOrder={getColumnSortingOrder("created")}
						render={(created: Project["created"]) => (
							<b>{new Date(created).toLocaleDateString("fr-CH")}</b>
						)}
					/>

					<Table.Column
						key="name"
						title={LL.columns.projectName()}
						dataIndex="name"
						width={250}
						sorter
						sortOrder={getColumnSortingOrder("name")}
						render={(name: Project["name"]) => (name.length ? name : "-")}
					/>

					<Table.Column
						key="description"
						title={LL.columns.description()}
						width={250}
						dataIndex="description"
						render={(description: Project["description"], { id }: Project) => {
							if (!description?.length) return "-";

							const isSelected = selectedRowKeys.some((key) => id === key);
							return isSelected
								? description
								: truncateString(description, DESCRIPTION_MAX_LENGTH);
						}}
					/>

					<Table.Column
						key="usersRoles"
						title={LL.columns.users.title()}
						dataIndex="usersRoles"
						width={150}
						render={(users: Project["usersRoles"], { id, externalIntervenant, fileReferent }: Project) => {
							const isSelected = selectedRowKeys.some((key) => id === key);

							const leader = users?.find(
								(user) => user.role === USER_PROJECT_ROLES.LEADER,
							)?.user;
							const referent = users?.find(
								(user) => user.role === USER_PROJECT_ROLES.REFERENT,
							)?.user;

							return isSelected ? (
								<ul className="list-none p-0">
									<li>
										<b>{LL.columns.users.leader()}: </b>
										{leader ? `${leader.name} ${leader.lastName}`  : "-"}
									</li>

									<li>
										<b>{LL.columns.users.referent()}: </b>
										{referent ? `${referent.name} ${referent.lastName}`  : "-"}
									</li>

									<li>
										<b className="p-0">{LL.columns.users.intervenantExternal()}: </b>
										{externalIntervenant ? `${externalIntervenant.name} ${externalIntervenant.lastName}`  : "-"}
									</li>
									<li>
										<b className="p-0">{LL.columns.users.referentExternal()}: </b>
										{externalIntervenant ? `${externalIntervenant.name} ${externalIntervenant.lastName}`  : "-"}
									</li>
								</ul>
							) : (
								<>
									<b>{LL.columns.users.leader()}</b>

									<div className="flex items-center justify-between gap-4">
										<p className="m-0">{leader ? `${leader.name} ${leader.lastName}`  : "-"}</p>

										<Popover
											placement="bottom"
											title="Équipe"
											arrow={false}
											content={
												<ul style={{ listStyle: "none", padding: 0 }}>
													<li>
														<b>{LL.columns.users.leader()}: </b>
														{leader ? `${leader.name} ${leader.lastName}`  : "-"}
													</li>

													<li>
														<b>{LL.columns.users.referent()}: </b>
														{referent ? `${referent.name} ${referent.lastName}`  : "-"}
													</li>

													<li>
														<b className="p-0">{LL.columns.users.intervenantExternal()}: </b>
														{externalIntervenant ? `${externalIntervenant.name} ${externalIntervenant.lastName}`  : "-"}
													</li>
													<li>
														<b className="p-0">{LL.columns.users.referentExternal()}: </b>
														{externalIntervenant ? `${externalIntervenant.name} ${externalIntervenant.lastName}`  : "-"}
													</li>
												</ul>
											}
										>
											<InfoCircleOutlined />
										</Popover>
									</div>
								</>
							);
						}}
					/>

					<Table.Column
						key="statuses"
						sorter
						sortOrder={getColumnSortingOrder("statuses")}
						title={LL.columns.status.title()}
						dataIndex="active"
						width={100}
						filteredValue={reqParams?.statuses}
						filters={[
							{ text: LL.columns.status.active(), value: true },
							{ text: LL.columns.status.off(), value: false },
						]}
						render={(isActive: Project["active"]) => (
							<Tag
								className="w-full rounded-full px-5 py-1 text-center text-xs font-thin mx-auto block"
								color={isActive ? "success" : "default"}
							>
								{isActive
									? LL.columns.status.active()
									: LL.columns.status.off()}
							</Tag>
						)}
					/>

					<Table.Column
						key="stageIds"
						sorter
						sortOrder={getColumnSortingOrder("stageIds")}
						width={150}
						filteredValue={reqParams?.stageIds}
						filters={stages?.map((stage) => ({
							text: stage.name,
							value: stage.id,
						}))}
						title={LL.columns.progression()}
						dataIndex="stage"
						render={(stage: Project["stage"]) => stage?.label}
					/>

					<Table.Column
						key="alerts"
						title={LL.columns.alerts.label()}
						width={150}
						dataIndex="alerts"
						render={(alerts: Project["alerts"], project: Project) => (
							<AlertColumn
								alerts={alerts}
								project={project}
								selectedRowKeys={selectedRowKeys}
							/>
						)}
					/>

					<Table.Column
						key="dueDate"
						sorter
						sortOrder={getColumnSortingOrder("dueDate")}
						width={150}
						title={LL.columns.dueDate()}
						dataIndex="dueDate"
						className="font-bold"
						render={(dueDate: Project["dueDate"]) =>
							dueDate ? (
								new Date(dueDate).toLocaleDateString("fr-CH")
							) : (
								// TODO: there is no state in the design that accounts for no dueDate add it once it's been added
								<span className="text-gray-400">-</span>
							)
						}
					/>

					<Table.Column
						key="editButton"
						width={30}
						render={(_, { id, accessLevel }: Project) =>
							canEdit(accessLevel) ? (
								<FormOutlined
									className="block cursor-pointer text-right text-xl text-[#5e9ad3]"
									onClick={() => {
										handleRedirectoToProjectEditFrom(id);
									}}
								/>
							) : (
								<EyeOutlined
									className="cursor-pointer text-lg text-[#5e9ad3]"
									onClick={() => {
										handleRedirectoToProjectEditFrom(id);
									}}
								/>
							)
						}
					/>
				</Table>
			</Card>

			<Modal
				destroyOnClose
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				getContainer={document.getElementById("root")!}
				width={640}
				open={showCreateProjectModal}
				onCancel={() => {
					toggleCreateProjectModal();
				}}
				className="pointer-events-auto"
				modalRender={() => (
					<CreateProjectModal
						toggleCreateProjectModal={toggleCreateProjectModal}
					/>
				)}
			/>

			<Button
				onClick={() => {
					generatePdf();
				}}
				type="default"
				className="flex self-start border-none bg-[#343434] text-white"
			>
				{LL.printButton()}
				<PrinterFilled className="text-lg" />
			</Button>
		</ConfigProvider>
	);
}
