import { ItemType } from "antd/es/menu/hooks/useItems";
import { ReactNode, createContext, useState } from "react";
import { z } from "zod";

export const menuKeys = z.enum([
	"global",
	"users",
	"contractors",
	"establishments",
	"external-establishments",
]);
export type MenuItem = { key: z.infer<typeof menuKeys> } & Omit<
	ItemType,
	"key"
>;

type DrawerContext = {
	isDrawerOpened: boolean;
	toggleDrawer: () => void;
	openDrawer: () => void;
	closeDrawer: () => void;
	currentTab: MenuItem["key"];
	setCurrentTab: (tab: MenuItem["key"]) => void;
};

export const DrawerContext = createContext<DrawerContext>({} as DrawerContext);

type Props = {
	children: ReactNode;
};

export function DrawerProvider({ children }: Props) {
	const [isDrawerOpened, setIsDrawerOpened] = useState(false);

	const [currentTab, setCurrentTab] = useState<MenuItem["key"]>("global");

	const openDrawer = () => {
		setIsDrawerOpened(true);
	};
	const toggleDrawer = () => {
		setIsDrawerOpened(!isDrawerOpened);
	};

	const closeDrawer = () => {
		setIsDrawerOpened(false);
	};

	return (
		<DrawerContext.Provider
			value={{
				isDrawerOpened,
				openDrawer,
				toggleDrawer,
				closeDrawer,
				currentTab,
				setCurrentTab,
			}}
		>
			{children}
		</DrawerContext.Provider>
	);
}
