import { DeleteFilled, DragOutlined, EyeFilled } from "@ant-design/icons";
import { Button, ButtonProps, Card, ConfigProvider, Upload } from "antd";
import { ReactNode, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { LocalizedString } from "typesafe-i18n";

import Label from "@/components/Label";
import { env } from "@/env";
import cn from "@/utils/cn";
import { ProjectFile } from "@/utils/schemas";
import {useI18nContext} from "@/i18n/i18n-react";

export type DocumentsListProps<T extends number> = {
	title: string;
	icon?: ReactNode;
	addButton: {
		text: LocalizedString;
	} & Omit<ButtonProps, "type">;
	onUpload: (file: File, type: T) => void;
	onRemove: (fileId: number) => void;
	type: T;
	files: ProjectFile[];
	index: number;
	isUploading: boolean;
	canEdit?: boolean;
};
export default function DocumentsList<T extends number>({
	title,
	icon,
	addButton: { text, ...buttonProps },
	onUpload,
	type,
	files,
	index,
	onRemove,
	isUploading,
	canEdit,
}: DocumentsListProps<T>) {
	const [inputWithDrag, setInputWithDrag] = useState(false);

	const {LL} = useI18nContext()
	const onFileOpen = (path: string) =>
		open(`${env.VITE_PUBLIC_MEDIA_URL}${path}`);
	return (
		<>
			<ConfigProvider
				theme={{
					components: { Card: { padding: 0 } },
				}}
			>
				<div className="flex w-full h-full flex-col">
					<Upload
						beforeUpload={() => false}
						disabled={!canEdit}
						showUploadList={false}
						multiple={false}
						style={{ width: "100%" }}
						onChange={({ file }) => {
							if (file.status !== "uploading") {
								onUpload((file.originFileObj ?? file) as File, type);
							}
						}}
					>
						<div
							className={cn(
								"@3xs:flex-row mb-2 flex flex-row items-center justify-between",
							)}
							onDragOver={() => {
								setInputWithDrag(true);
							}}
							onDragLeave={() => {
								setInputWithDrag(false);
							}}
							onDrop={() => {
								setInputWithDrag(false);
							}}
							style={{
								width: "100%",
								border: "1px solid rgba(0, 0, 0, 0.3)",
								borderRadius: 8,
								padding: 3,
							}}
						>
							<Label icon={icon}>{title}</Label>

							{canEdit && (
								<Button
									loading={isUploading}
									type="link"
									className={cn(
										"@3xs:self-auto self-start text-xs text-[#a2a2a2]",
										buttonProps.className,
									)}
									{...buttonProps}
								>
									<span className="mr-1 underline">
										{inputWithDrag ? LL.buttons.dropHere() : text}
									</span>{""}
									+
								</Button>
							)}
						</div>
					</Upload>

					<Droppable key={type} droppableId={`${index}`}>
						{(provided) => (
							<Card
								bordered={false}
								className="flex-1 overflow-y-auto rounded-sm bg-[#fafafa] shadow-none min-h-[100px] p-15"
								bodyStyle={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
								}}
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{files.map((file, ind) => (
									<Draggable
										key={file.id}
										draggableId={file.id.toString()}
										index={ind}
										isDragDisabled={!canEdit}
									>
										{(provided) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={{
													...provided.draggableProps.style,
													userSelect: "none",
												}}
											>
												<div
													key={file.id}
													className="flex flex-row items-center hover:bg-white"
												>
													<div className="flex flex-1 cursor-move flex-row gap-2 p-3">
														{canEdit && <DragOutlined />}

														<p className="m-0">{file.name}</p>
													</div>

													<div className="flex flex-row gap-2 pr-3 text-[#5e9ad3]">
														<Button
															className={"p-0"}
															type={"text"}
															onClick={() => {
																onFileOpen(file.path);
															}}
															icon={
																<EyeFilled
																	color={"#5e9ad3"}
																	className="cursor-pointer text-[#5e9ad3]"
																/>
															}
														/>

														{canEdit && (
															<Button
																className={"p-0"}
																type={"text"}
																onClick={() => {
																	onRemove(file.id);
																}}
																icon={
																	<DeleteFilled
																		color={"#5e9ad3"}
																		className="cursor-pointer text-[#5e9ad3]"
																	/>
																}
															/>
														)}
													</div>
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</Card>
						)}
					</Droppable>
				</div>
			</ConfigProvider>
		</>
	);
}
