export default function whatHasChangedInArray<T>(
	newArray: T[],
	oldArray?: T[],
): { added: T[]; deleted: T[] } {
	const added: T[] = [];
	const deleted: T[] = [];

	if (!oldArray) return { added: newArray, deleted };

	const longerArray = newArray.length > oldArray.length ? newArray : oldArray;

	for (let i = 0; i < longerArray.length; i++) {
		// Check what's been added
		{
			const value = newArray.at(i);
			if (
				value &&
				!oldArray.some((val) => JSON.stringify(value) === JSON.stringify(val))
			)
				added.push(value);
		}

		// Check what's been deleted
		{
			const value = oldArray.at(i);
			if (
				value &&
				!newArray.some((val) => JSON.stringify(value) === JSON.stringify(val))
			)
				deleted.push(value);
		}
	}

	return { added, deleted };
}
