import { CloseOutlined, FormOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Divider, Typography } from "antd";
import { useContext, useEffect, useState } from "react";

import {
	createEstablishment,
	deleteEstablishment,
	updateEstablishment,
} from "@/api";
import { DrawerContext, useToast } from "@/context";
import mutationKeys from "@/definitions/mutation-keys";
import useEstablishmentData from "@/hooks/query/useEstablishmentData";
import { useI18nContext } from "@/i18n/i18n-react";
import {
	Establishment,
	EstablishmentType,
	ExternalEstablishment,
} from "@/utils/schemas/establishment";

import EstablishmentForm from "./EstablishmentForm";

type EstablishmentsProps = {
	type?: EstablishmentType;
};

export default function Establishments({
	type = "internal",
}: EstablishmentsProps) {
	const {
		LL: {
			ProjectModule: { settings: LL },
			toasts: ToastLL,
		},
	} = useI18nContext();
	const queryClient = useQueryClient();

	const { toast } = useToast();

	const { data: establishments } = useEstablishmentData({ type });

	const [selectedEstablishment, setSelectedEstablishment] = useState<
		Partial<Establishment> | Partial<ExternalEstablishment> | null
	>(null);
	function handleChangeSelectedEstablishment(
		establishment:
			| Partial<Establishment>
			| Partial<ExternalEstablishment>
			| null,
	) {
		setSelectedEstablishment(establishment);
	}

	useEffect(() => {
		handleChangeSelectedEstablishment(null);
	}, [type]);

	const { mutateAsync: mutateEstablishment } = useMutation({
		mutationKey: mutationKeys.establishments([type, selectedEstablishment?.id]),
		mutationFn: (vars: Establishment | ExternalEstablishment) =>
			selectedEstablishment?.id
				? updateEstablishment({ ...vars, id: selectedEstablishment.id }, type)
				: createEstablishment(vars, type),
		retry: 0,
		onSuccess: async () => {
			handleChangeSelectedEstablishment(null);
			await queryClient.invalidateQueries();
			toast.success({
				message: ToastLL.success(),
				description: selectedEstablishment?.id
					? ToastLL.updatedSuccessfully()
					: ToastLL.createdSuccessfully(),
			});
		},
		onError: (e: any) => {
			console.error(e);
			toast.error({
				message: ToastLL.error(),
				description: e.message ?? ToastLL.somethingIsNoYes(),
			});
		},
	});

	const { closeDrawer } = useContext(DrawerContext);

	const handleDeleteEstablishment = async (id: number) => {
		await deleteEstablishment(id, type);
		handleChangeSelectedEstablishment(null);
		await queryClient.invalidateQueries();
		toast.success({
			message: ToastLL.success(),
			description: ToastLL.deletedSuccessfully(),
		});
	};

	return (
		<>
			<Typography className="flex justify-between">
				<div className="flex flex-1 gap-5">
					<Typography.Title level={2} className="mb-0">
						{type === "external"
							? LL.tabs.establishments.externalTitle()
							: LL.tabs.establishments.title()}
					</Typography.Title>

					<Button
						type="link"
						onClick={() => {
							handleChangeSelectedEstablishment(null);
							setTimeout(() => {
								handleChangeSelectedEstablishment({});
							});
						}}
						className="self-end p-0 text-xs text-[#a2a2a2]"
					>
						<span className="underline">{LL.addOptionButton()}</span> +
					</Button>
				</div>

				<CloseOutlined className="cursor-pointer" onClick={closeDrawer} />
			</Typography>

			<Divider className="my-2" />

			<Card
				className="mb-10 max-h-[380px] min-h-[380px] border-[#343434] overflow-y-auto"
				bodyStyle={{
					padding: 12,
					display: "flex",
					flexDirection: "column",
					justifyItems: "center",
					alignItems: "center",
				}}
			>
				{establishments?.map((establishment) => {
					const { id, name } = establishment;
					return (
						<div
							key={id}
							className="cursor-pointer flex w-full flex-row items-center justify-between even:bg-[#fafafa] py-5 pl-4 pr-3"
							onClick={() => {
								handleChangeSelectedEstablishment(establishment);
							}}
						>
							<div className="flex flex-col">
								<span className="font-bold">{name}</span>

								<span className="max-w-[35ch] whitespace-nowrap overflow-hidden text-ellipsis text-[#a2a2a2]"></span>
							</div>

							<FormOutlined className="cursor-pointer text-lg text-[#5e9ad3]" />
						</div>
					);
				})}
			</Card>

			{selectedEstablishment !== null ? (
				<EstablishmentForm
					type={type}
					handleChangeSelectedEstablishment={handleChangeSelectedEstablishment}
					onDelete={handleDeleteEstablishment}
					initValues={selectedEstablishment}
					onSubmit={mutateEstablishment}
				/>
			) : null}
		</>
	);
}
