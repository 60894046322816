import { z } from "zod";

const financingType = z.object({
	id: z.number(),
	name: z.string(),
	created: z.string(),
	updated: z.string(),
	active: z.boolean().nullable(),
});
export default financingType;

export type Financing = z.infer<typeof financingType>;
